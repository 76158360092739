import { ref } from "vue";
import { useToast } from "vue-toastification";
import api from "@/services/api";

export function useInvoiceDownload() {
    const toast = useToast();
    const isDownloading = ref(false);

    const getDownloadFileName = (invoiceNumber) => {
        const date = new Date().toISOString().split("T")[0]; // YYYY-MM-DD
        return `Invoice_${invoiceNumber}_${date}.pdf`;
    };

    const downloadInvoice = async (invoice) => {
        if (isDownloading.value) return;

        isDownloading.value = true;
        try {
            const response = await api.post(
                "/generate-pdf",
                { invoiceId: invoice.ID },
                { responseType: "blob" }
            );

            // Create blob and download
            const blob = new Blob([response.data], { type: "application/pdf" });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");

            // Set up download
            link.href = url;
            link.download = getDownloadFileName(invoice.InvoiceNumber);

            // Trigger download
            document.body.appendChild(link);
            link.click();

            // Cleanup
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);

            toast.success("Invoice generated successfully");
        } catch (error) {
            toast.error(
                error?.response?.data?.message ||
                    "Failed to generate invoice. Please contact support."
            );
        } finally {
            isDownloading.value = false;
        }
    };

    return {
        isDownloading,
        downloadInvoice,
    };
}
