<script setup>
import { cn } from '@/lib/utils'
import { useVModel } from '@vueuse/core'

const props = defineProps({
    defaultValue: {
        type: [String, Number],
        default: ''
    },
    modelValue: {
        type: [String, Number],
        default: ''
    },
    class: {
        type: String,
        default: ''
    }
})

const emit = defineEmits(['update:modelValue'])

const modelValue = useVModel(props, 'modelValue', emit, {
    passive: true,
    defaultValue: props.defaultValue,
})
</script>

<template>
    <input v-model="modelValue" :class="cn(
        'p-3 border border-gray-600 rounded-lg lg:w-full w-full bg-gray-700 text-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300',
        props.class
    )">
</template>