<template>
    <div>Processing login...</div>
</template>

<script>
import { onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'

export default {
    setup() {
        const store = useStore()
        const router = useRouter()
        const route = useRoute()

        onMounted(async () => {
            const code = route.query.code
            if (code) {
                try {
                    await store.dispatch('auth/handleGoogleCallback', code)
                    router.push('/')
                } catch (error) {
                    console.error('Login failed:', error)
                    router.push('/login')
                }
            } else {
                router.push('/login')
            }
        })
    }
}
</script>