<template>
    <!-- Loader -->
    <div v-if="isLoading" class="absolute inset-0 flex items-center justify-center z-20 bg-gray-800 bg-opacity-75">
        <svg class="animate-spin h-8 w-8 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
            </path>
        </svg>
    </div>

    <div v-else class="h-full bg-gradient-to-br from-gray-900 to-gray-800 p-8 relative">
        <!-- Header -->
        <div class="mb-8 relative z-10">
            <h1 class="text-4xl font-bold text-white">User Profile</h1>
        </div>

        <!-- Main Content Grid -->
        <div class="grid grid-cols-1 xl:grid-cols-3 gap-6 pb-6">
            <!-- Left Column -->
            <div class="space-y-6">
                <!-- Profile Form -->
                <form @submit.prevent="updateProfile"
                    class="bg-gray-800 shadow-lg rounded-lg px-8 pt-6 pb-8 relative z-10">
                    <div class="space-y-6" v-if="userProfile">
                        <!-- Profile Photo -->
                        <div class="flex flex-col items-center space-y-4">
                            <div class="relative">
                                <template v-if="userProfile.Settings.LogoURL">
                                    <img :src="userProfile.Settings.LogoURL"
                                        class="w-32 h-32 rounded-full object-cover border-4 border-purple-500"
                                        alt="Company Logo" />
                                </template>
                                <template v-else>
                                    <svg class="w-32 h-32 rounded-full border-4 border-purple-500" viewBox="0 0 64 64"
                                        fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="64" height="64" fill="#202a37" /> <!-- Light gray background -->
                                        <path
                                            d="M32 16C26.48 16 22 20.48 22 26C22 31.52 26.48 36 32 36C37.52 36 42 31.52 42 26C42 20.48 37.52 16 32 16ZM32 39C25.33 39 12 42.34 12 49V52H52V49C52 42.34 38.67 39 32 39Z"
                                            fill="#A855F7" /> <!-- Purple-500 to match your border -->
                                    </svg>
                                </template>
                                <!-- <button type="button"
                                    class="absolute bottom-0 right-0 bg-purple-500 p-2 rounded-full hover:bg-purple-600 transition-colors">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-white"
                                        viewBox="0 0 20 20" fill="currentColor">
                                        <path
                                            d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                                    </svg>
                                </button> -->
                            </div>
                        </div>

                        <!-- Name Input -->
                        <div class="space-y-2">
                            <label class="block text-gray-300 text-sm font-bold mb-2" for="name">
                                Name
                            </label>
                            <input
                                class="opacity-20 w-full p-3 border border-gray-600 rounded-lg bg-gray-700 text-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
                                id="name" type="text" v-model="userProfile.Name" placeholder="Your Name" disabled>
                        </div>

                        <!-- Email Input -->
                        <div class="space-y-2">
                            <label class="block text-gray-300 text-sm font-bold mb-2" for="email">
                                Email
                            </label>
                            <input
                                class="opacity-20 w-full p-3 border border-gray-600 rounded-lg bg-gray-700 text-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
                                id="email" type="email" v-model="userProfile.Email" placeholder="your@email.com"
                                disabled>
                        </div>

                        <!-- Company Input -->
                        <div class="space-y-2">
                            <label class="block text-gray-300 text-sm font-bold mb-2" for="company">
                                Company
                            </label>
                            <input
                                class="w-full p-3 border border-gray-600 rounded-lg bg-gray-700 text-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
                                id="company" type="text" v-model="userProfile.Settings.CompanyName"
                                placeholder="Your Company">
                        </div>

                        <!-- Company Input -->
                        <div class="space-y-2">
                            <label class="block text-gray-300 text-sm font-bold mb-2" for="company">
                                Company VAT
                            </label>
                            <input
                                class="w-full p-3 border border-gray-600 rounded-lg bg-gray-700 text-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
                                id="company" type="text" v-model="userProfile.Settings.UserCompanyVAT"
                                placeholder="Your Company">
                        </div>

                        <!-- Update Button -->
                        <button type="submit"
                            class="w-full bg-gradient-to-r from-blue-500 to-purple-600 text-white px-6 py-3 rounded-lg shadow-lg hover:from-blue-600 hover:to-purple-700 transition duration-300 transform font-semibold">
                            Update Profile
                        </button>
                    </div>
                </form>

            </div>

            <!-- Right Column -->
            <div class="space-y-6" v-if="userProfile">
                <!-- Subscription Widget -->
                <div class="bg-gray-800 shadow-lg rounded-lg p-6">
                    <div class="flex items-center justify-between mb-6">
                        <h2 class="text-xl font-bold text-white">Current Plan</h2>
                        <span v-if="userProfile.Subscription" class="px-4 py-2 rounded-full" :class="{
                            'bg-green-500': userProfile.Subscription.Status === 'active',
                            'bg-yellow-500': userProfile.Subscription.Status === 'trialing',
                            'bg-red-500': userProfile.Subscription.Status === 'canceled',
                            'bg-gray-500': !userProfile.Subscription.Status
                        }">
                            {{ userProfile.Subscription.PlanName || 'No Plan' }}
                        </span>
                    </div>

                    <div v-if="userProfile.Subscription" class="space-y-4">
                        <!-- Plan details -->
                        <div class="text-gray-300">
                            <p>Status: {{ userProfile.Subscription.Status || 'No active subscription' }}</p>
                            <p v-if="userProfile.Subscription.TrialEnd">Trial ends: {{
                                formatDate(userProfile.Subscription.TrialEnd) }}</p>
                            <p v-if="userProfile.Subscription.CurrentPeriodEnd && userProfile.Subscription.Status">
                                Current period ends: {{
                                    formatDate(userProfile.Subscription.CurrentPeriodEnd) }}</p>
                        </div>

                        <!-- Features based on plan -->
                        <div class="space-y-2">
                            <div v-for="feature in getPlanFeatures(userProfile.Subscription.PlanName)" :key="feature"
                                class="flex items-center space-x-3 text-gray-300">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-green-500"
                                    viewBox="0 0 20 20" fill="currentColor">
                                    <path fill-rule="evenodd"
                                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                        clip-rule="evenodd" />
                                </svg>
                                <span>{{ feature }}</span>
                            </div>
                        </div>
                    </div>

                    <div v-else class="text-center py-8">
                        <svg class="mx-auto h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24"
                            stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z" />
                        </svg>
                        <h3 class="mt-2 text-sm font-medium text-gray-300">No Subscription</h3>
                        <p class="mt-1 text-sm text-gray-400">Get started by subscribing to a plan.</p>
                    </div>

                    <div class="mt-6 space-y-4">
                        <!-- Show upgrade button only if applicable -->
                        <button v-if="canUpgrade" @click="upgradeSubscription"
                            class="w-full bg-gradient-to-r from-green-500 to-emerald-600 text-white px-6 py-3 rounded-lg shadow-lg hover:from-green-600 hover:to-emerald-700 transition duration-300 transform font-semibold">
                            {{ userProfile.Subscription ? `Upgrade to ${getNextPlan}` : 'Get Started' }}
                        </button>

                        <!-- Show cancel button only if subscription is active -->
                        <button v-if="canCancel" @click="confirmCancelSubscription"
                            class="w-full bg-gradient-to-r from-red-500 to-pink-600 text-white px-6 py-3 rounded-lg shadow-lg hover:from-red-600 hover:to-pink-700 transition duration-300 transform font-semibold">
                            {{ userProfile.Subscription?.CancelAtPeriodEnd ? 'Resume Subscription' :
                                'Cancel Subscription' }}
                        </button>
                    </div>
                </div>

                <!-- Recent Invoices Widget -->
                <SubscriptionLock v-if="!hasActiveSubscription" title="Access Recent Invoices"
                    @upgrade="upgradeSubscription">
                    <div class="bg-gray-800 shadow-lg rounded-lg p-6">
                        <h2 class="text-xl font-bold text-white mb-4">Recent Invoices</h2>
                        <div v-if="recentInvoices && recentInvoices.length > 0" class="space-y-4">
                            <div v-for="invoice in recentInvoices" :key="invoice.id" class="bg-gray-700 p-4 rounded-lg">
                                <div class="flex justify-between items-center mb-2">
                                    <span class="text-gray-200 font-semibold">#{{ invoice.InvoiceNumber }}</span>
                                    <span :class="getStatusClass(invoice.Status)">{{ invoice.Status }}</span>
                                </div>
                                <div class="flex justify-between items-center">
                                    <span class="text-gray-400 text-sm">{{ formatDate(invoice.IssueDate) }}</span>
                                    <span class="text-gray-200 font-bold">{{ formatCurrency(invoice.Amount,
                                        invoice.Currency) }}</span>
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            <div class=" space-y-4">
                                <div class="flex justify-between items-center mb-2">
                                    <span class="text-gray-200 font-semibold">#123</span>
                                    <span :class="getStatusClass('Paid')">Paid</span>
                                </div>
                                <div class="flex justify-between items-center">
                                    <span class="text-gray-400 text-sm">11.10.2024</span>
                                    <span class="text-gray-200 font-bold">3000 EUR</span>
                                </div>
                            </div>
                            <div class=" space-y-4">
                                <div class="flex justify-between items-center mb-2">
                                    <span class="text-gray-200 font-semibold">#123</span>
                                    <span :class="getStatusClass('Draft')">Paid</span>
                                </div>
                                <div class="flex justify-between items-center">
                                    <span class="text-gray-400 text-sm">11.10.2024</span>
                                    <span class="text-gray-200 font-bold">3000 EUR</span>
                                </div>
                            </div>
                            <div class=" space-y-4">
                                <div class="flex justify-between items-center mb-2">
                                    <span class="text-gray-200 font-semibold">#123</span>
                                    <span :class="getStatusClass('Pending')">Paid</span>
                                </div>
                                <div class="flex justify-between items-center">
                                    <span class="text-gray-400 text-sm">11.10.2024</span>
                                    <span class="text-gray-200 font-bold">3000 EUR</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </SubscriptionLock>
                <div v-else class="bg-gray-800 shadow-lg rounded-lg p-6">
                    <h2 class="text-xl font-bold text-white mb-4">Recent Invoices</h2>
                    <div v-if="recentInvoices && recentInvoices.length > 0" class="space-y-4">
                        <div v-for="invoice in recentInvoices" :key="invoice.id" class="bg-gray-700 p-4 rounded-lg">
                            <div class="flex justify-between items-center mb-2">
                                <span class="text-gray-200 font-semibold">#{{ invoice.InvoiceNumber }}</span>
                                <span :class="getStatusClass(invoice.Status)">{{ invoice.Status }}</span>
                            </div>
                            <div class="flex justify-between items-center">
                                <span class="text-gray-400 text-sm">{{ formatDate(invoice.IssueDate) }}</span>
                                <span class="text-gray-200 font-bold">{{ formatCurrency(invoice.Amount,
                                    invoice.Currency) }}</span>
                            </div>
                        </div>
                    </div>
                    <div v-else class="text-center py-8">
                        <svg class="mx-auto h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24"
                            stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                        </svg>
                        <h3 class="mt-2 text-sm font-medium text-gray-300">No invoices</h3>
                        <p class="mt-1 text-sm text-gray-400">Get started by creating a new invoice.</p>
                    </div>
                    <button @click="viewAllInvoices"
                        class="w-full mt-4 bg-gray-700 text-gray-200 px-6 py-3 rounded-lg hover:bg-gray-600 transition duration-300 font-semibold">
                        View All Invoices
                    </button>
                </div>
            </div>

            <!-- Middle Column -->
            <div class="space-y-6">
                <SubscriptionLock v-if="!hasActiveSubscription" title="Access Quick Stats"
                    @upgrade="upgradeSubscription">
                    <div class="bg-gray-800 shadow-lg rounded-lg p-6">
                        <h2 class="text-xl font-bold text-white mb-4">Quick Stats</h2>
                        <div v-if="stats && hasStats" class="grid grid-cols-1 xs:grid-cols-2 gap-4">
                            <div class="bg-gray-700 p-4 rounded-lg">
                                <div class="text-gray-400 text-sm">Total Revenue</div>
                                <div class="text-green-400 text-xl font-bold">{{ formatCurrency(stats.totalRevenue) }}
                                </div>
                            </div>
                            <div class="bg-gray-700 p-4 rounded-lg">
                                <div class="text-gray-400 text-sm">Pending Payments</div>
                                <div class="text-yellow-400 text-xl font-bold">{{ formatCurrency(stats.pendingPayments)
                                    }}
                                </div>
                            </div>
                            <div class="bg-gray-700 p-4 rounded-lg">
                                <div class="text-gray-400 text-sm">Active Clients</div>
                                <div class="text-blue-400 text-xl font-bold">{{ stats.totalCustomers }}</div>
                            </div>
                            <div class="bg-gray-700 p-4 rounded-lg">
                                <div class="text-gray-400 text-sm">Total Invoices</div>
                                <div class="text-purple-400 text-xl font-bold">{{ stats.totalInvoices }}</div>
                            </div>
                        </div>
                        <div v-else class="text-center py-8">
                            <svg class="mx-auto h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24"
                                stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                    d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
                            </svg>
                            <h3 class="mt-2 text-sm font-medium text-gray-300">No stats available</h3>
                            <p class="mt-1 text-sm text-gray-400">Stats will appear as you use the platform.</p>
                        </div>
                    </div>
                </SubscriptionLock>
                <!-- Quick Stats Widget -->
                <div v-else class="bg-gray-800 shadow-lg rounded-lg p-6">
                    <h2 class="text-xl font-bold text-white mb-4">Quick Stats</h2>
                    <div v-if="stats && hasStats" class="grid grid-cols-1 xs:grid-cols-2 gap-4">
                        <div class="bg-gray-700 p-4 rounded-lg">
                            <div class="text-gray-400 text-sm">Total Revenue</div>
                            <div class="text-green-400 text-xl font-bold">{{ formatCurrency(stats.totalRevenue) }}</div>
                        </div>
                        <div class="bg-gray-700 p-4 rounded-lg">
                            <div class="text-gray-400 text-sm">Pending Payments</div>
                            <div class="text-yellow-400 text-xl font-bold">{{ formatCurrency(stats.pendingPayments) }}
                            </div>
                        </div>
                        <div class="bg-gray-700 p-4 rounded-lg">
                            <div class="text-gray-400 text-sm">Active Clients</div>
                            <div class="text-blue-400 text-xl font-bold">{{ stats.totalCustomers }}</div>
                        </div>
                        <div class="bg-gray-700 p-4 rounded-lg">
                            <div class="text-gray-400 text-sm">Total Invoices</div>
                            <div class="text-purple-400 text-xl font-bold">{{ stats.totalInvoices }}</div>
                        </div>
                    </div>
                    <div v-else class="text-center py-8">
                        <svg class="mx-auto h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24"
                            stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
                        </svg>
                        <h3 class="mt-2 text-sm font-medium text-gray-300">No stats available</h3>
                        <p class="mt-1 text-sm text-gray-400">Stats will appear as you use the platform.</p>
                    </div>
                </div>

                <!-- Top Clients Widget -->
                <SubscriptionLock v-if="!hasActiveSubscription" title="Access Top Clients"
                    @upgrade="upgradeSubscription">
                    <div class="bg-gray-800 shadow-lg rounded-lg p-6">
                        <h2 class="text-xl font-bold text-white mb-4">Top Clients</h2>
                        <div v-if="topClients && topClients.length > 0" class="space-y-4">
                            <div v-for="client in topClients" :key="client.ID"
                                class="bg-gray-700 p-4 rounded-lg flex justify-between items-center">
                                <div class="flex items-center space-x-3">
                                    <div
                                        class="w-10 h-10 rounded-full bg-gradient-to-r from-blue-500 to-purple-600 flex items-center justify-center text-white font-bold">
                                        {{ getInitials(client.CustomerName) }}
                                    </div>
                                    <div>
                                        <p class="text-gray-200 font-semibold">{{ client.CustomerName }}</p>
                                        <p class="text-gray-400 text-sm">{{ client.TotalInvoices }} invoices</p>
                                    </div>
                                </div>
                                <div class="text-right">
                                    <p class="text-green-400 font-bold">{{ formatCurrency(client.TotalAmount) }}</p>
                                    <p class="text-gray-400 text-sm">Total Revenue</p>
                                </div>
                            </div>
                        </div>
                        <div v-else class="text-center py-8">
                            <svg class="mx-auto h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24"
                                stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                    d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
                            </svg>
                            <h3 class="mt-2 text-sm font-medium text-gray-300">No clients yet</h3>
                            <p class="mt-1 text-sm text-gray-400">Start adding clients to see them here.</p>
                        </div>
                    </div>
                </SubscriptionLock>
                <div v-else class="bg-gray-800 shadow-lg rounded-lg p-6">
                    <h2 class="text-xl font-bold text-white mb-4">Top Clients</h2>
                    <div v-if="topClients && topClients.length > 0" class="space-y-4">
                        <div v-for="client in topClients" :key="client.ID"
                            class="bg-gray-700 p-4 rounded-lg flex justify-between items-center">
                            <div class="flex items-center space-x-3">
                                <div
                                    class="w-10 h-10 rounded-full bg-gradient-to-r from-blue-500 to-purple-600 flex items-center justify-center text-white font-bold">
                                    {{ getInitials(client.CustomerName) }}
                                </div>
                                <div>
                                    <p class="text-gray-200 font-semibold">{{ client.CustomerName }}</p>
                                    <p class="text-gray-400 text-sm">{{ client.TotalInvoices }} invoices</p>
                                </div>
                            </div>
                            <div class="text-right">
                                <p class="text-green-400 font-bold">{{ formatCurrency(client.TotalAmount) }}</p>
                                <p class="text-gray-400 text-sm">Total Revenue</p>
                            </div>
                        </div>
                    </div>
                    <div v-else class="text-center py-8">
                        <svg class="mx-auto h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24"
                            stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
                        </svg>
                        <h3 class="mt-2 text-sm font-medium text-gray-300">No clients yet</h3>
                        <p class="mt-1 text-sm text-gray-400">Start adding clients to see them here.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useToast } from 'vue-toastification'
import SubscriptionLock from '@/components/SubscriptionLock.vue';

const toast = useToast()
const store = useStore()
const router = useRouter()

const isLoading = ref(false)

// User Profile State
const userProfile = ref(null)

// Stats Data
const stats = ref(null)

// Recent Activities Data
const recentActivities = ref([])

// Top Clients Data
const topClients = ref([])

// Recent Invoices Data
const recentInvoices = ref([])

const hasStats = computed(() => {
    return stats.value.totalRevenue > 0 ||
        stats.value.pendingPayments > 0 ||
        stats.value.activeClients > 0 ||
        stats.value.totalInvoices > 0
})

const hasActiveSubscription = computed(() => {
    if (userProfile.value) {
        return userProfile.value.Subscription.Status === "active" || userProfile.value.Subscription.Status === "trialing"
    }

    return false;
})

const formatCurrency = (amount, currency) => {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currency || 'USD'
    }).format(amount);
};


const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
    })
}

const getInitials = (name) => {
    return name
        .split(' ')
        .map(word => word[0])
        .join('')
        .toUpperCase()
        .slice(0, 2)
}

const getStatusClass = (status) => {
    const statusClasses = {
        'Paid': 'text-green-400',
        'Pending': 'text-yellow-400',
        'Overdue': 'text-red-400',
        'Draft': 'text-gray-400'
    }
    return statusClasses[status] || 'text-gray-400'
}

const getActivityIconClass = (type) => {
    const iconClasses = {
        'invoice': 'bg-blue-500/20 text-blue-500',
        'payment': 'bg-green-500/20 text-green-500',
        'client': 'bg-purple-500/20 text-purple-500',
        'subscription': 'bg-yellow-500/20 text-yellow-500'
    }
    return iconClasses[type] || 'bg-gray-500/20 text-gray-500'
}

const getActivityIcon = (type) => {
    const icons = {
        invoice: 'DocumentIcon',
        payment: 'CreditCardIcon',
        client: 'UserGroupIcon',
        subscription: 'StarIcon'
    }
    // You would need to import these icons from your icon library
    return icons[type]
}

const fetchUserProfile = async () => {
    try {
        const response = await store.dispatch('profile/fetchProfile')
        userProfile.value = response
    } catch (error) {
        console.error('Error fetching user profile:', error)
    }
}

const fetchStats = async () => {
    try {
        const response = await store.dispatch('profile/fetchStats')
        stats.value = response
    } catch (error) {
        console.error('Error fetching stats:', error)
    }
}

const fetchTopClients = async () => {
    try {
        const response = await store.dispatch('profile/fetchTopClients')
        topClients.value = response
    } catch (error) {
        console.error('Error fetching top clients:', error)
    }
}

const fetchRecentInvoices = async () => {
    try {
        const response = await store.dispatch('profile/fetchRecentInvoices')
        console.log(response);
        recentInvoices.value = response
    } catch (error) {
        console.error('Error fetching recent invoices:', error)
    }
}

// Action Handlers
const updateProfile = async () => {
    try {
        await store.dispatch('profile/updateProfile', userProfile.value)
        toast.success("Profile updated successfully!", {
            timeout: 2000
        })
    } catch (error) {
        console.error('Error updating profile:', error)
        toast.error('Failed to update profile. Please try again.')
    }
}

const cancelSubscription = async () => {
    if (confirm('Are you sure you want to cancel your subscription?')) {
        try {
            await store.dispatch('subscription/cancel')
            toast.warning('Subscription cancelled successfully.')
            await fetchUserProfile()
        } catch (error) {
            toast.error('Failed to cancel subscription. Please try again.')
        }
    }
}

// Add these to your script setup
const canUpgrade = computed(() => {
    const sub = userProfile.value.Subscription
    // Can upgrade if no subscription or on monthly plan
    return !sub ||
        (sub.Status === 'active' && sub.BillingCycle === 'monthly') ||
        sub.Status === 'canceled'
})

const canCancel = computed(() => {
    const sub = userProfile.value.Subscription
    return sub && (sub.Status === 'active' || sub.Status === 'trialing')
})

const getNextPlan = computed(() => {
    const currentPlan = userProfile.value.Subscription?.PlanName
    if (!currentPlan) return 'Pro Plan'
    if (currentPlan === 'Basic' && userProfile.value.Subscription.BillingCycle === 'monthly') return 'Pro Plan'
    return ''
})

const getPlanFeatures = (planName) => {
    const features = {
        'Basic': [
            '100 Invoices/month',
            'Basic Templates',
            'Email Support'
        ],
        'Pro': [
            'Unlimited Invoices',
            'Premium Templates',
            'Priority Support',
            'Advanced Analytics'
        ]
    }
    return features[planName] || []
}

const confirmCancelSubscription = async () => {
    const sub = userProfile.value.Subscription
    const action = sub.CancelAtPeriodEnd ? 'resume' : 'cancel'
    const message = sub.CancelAtPeriodEnd
        ? 'Are you sure you want to resume your subscription?'
        : 'Are you sure you want to cancel your subscription? You\'ll still have access until the end of your billing period.'

    if (confirm(message)) {
        try {
            if (sub.CancelAtPeriodEnd) {
                await store.dispatch('subscription/resume')
            } else {
                await store.dispatch('subscription/cancel')
            }
            await fetchUserProfile()
        } catch (error) {
            console.error(`Error ${action}ing subscription:`, error)
            toast.error(`Failed to ${action} subscription. Please try again.`)
        }
    }
}

const upgradeSubscription = async () => {
    router.push("/subscription")
}

const viewAllInvoices = () => {
    router.push('/')
}

// // Initialize Data
// onMounted(async () => {
//     await Promise.all([
//         fetchUserProfile(),
//         fetchStats(),
//         // fetchRecentActivities(),
//         fetchTopClients(),
//         fetchRecentInvoices()
//     ])
// })

onMounted(async () => {
    isLoading.value = true;
    try {
        // Simulate loading time before fetching data
        await new Promise(resolve => setTimeout(resolve, 300));

        await Promise.all([
            fetchUserProfile(),
            fetchStats(),
            // fetchRecentActivities(), // Uncomment if needed
            fetchTopClients(),
            fetchRecentInvoices()
        ]);
    } catch (error) {
        console.error('Failed to initialize data:', error);
    } finally {
        isLoading.value = false;
    }
});

</script>

<style scoped>
/* Add any component-specific styles here */
.activity-item {
    transition: all 0.3s ease;
}

.activity-item:hover {
    transform: translateX(8px);
}

/* Add smooth transitions for hover effects */
.bg-gray-700 {
    transition: all 0.3s ease;
}

.bg-gray-700:hover {
    background-color: rgba(55, 65, 81, 0.9);
}
</style>