<!-- ScrollBar.vue -->
<script setup>
import { useScroll } from '@/composables/useScroll';

const props = defineProps({
    className: {
        type: String,
        default: ''
    }
})

const { scrollAreaRef, scrollBarRef, scrollPercentage } = useScroll()
</script>

<template>
    <div ref="scrollBarRef" class="absolute right-1 top-1 z-10 h-[calc(100%-2rem)] w-2 rounded-full bg-gray-600/50"
        :style="{ height: `${scrollPercentage * 100}%` }" :class="props.className" />
</template>