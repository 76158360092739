import api from "@/services/api";

export default {
    namespaced: true,
    state: {
        isAuthenticated: false,
        user: null,
        token: localStorage.getItem("token") || null,
        loading: false,
        error: null,
    },
    getters: {
        isAuthenticated: (state) => state.isAuthenticated,
        user: (state) => state.user,
    },
    mutations: {
        SET_AUTH_STATUS(state, status) {
            state.isAuthenticated = status;
        },
        SET_USER(state, user) {
            state.user = user;
        },
        SET_TOKEN(state, token) {
            state.token = token;
            if (token) {
                localStorage.setItem("token", token);
            } else {
                localStorage.removeItem("token");
            }
        },
        SET_LOADING(state, loading) {
            state.loading = loading;
        },
        SET_ERROR(state, error) {
            state.error = error;
        },
    },
    actions: {
        async signup({ commit }, credentials) {
            try {
                commit("SET_LOADING", true);
                commit("SET_ERROR", null);
                const response = await api.post("/auth/signup", credentials);
                return response.data;
            } catch (error) {
                commit(
                    "SET_ERROR",
                    error.response?.data?.error || "Signup failed"
                );
                throw error;
            } finally {
                commit("SET_LOADING", false);
            }
        },

        async verifyEmail({ commit }, { email, token }) {
            try {
                commit("SET_LOADING", true);
                commit("SET_ERROR", null);
                const response = await api.post("/auth/verify-email", {
                    email,
                    token,
                });
                commit("SET_TOKEN", response.data.token);
                commit("SET_USER", response.data.user);
                return response.data;
            } catch (error) {
                commit(
                    "SET_ERROR",
                    error.response?.data?.error || "Verification failed"
                );
                throw error;
            } finally {
                commit("SET_LOADING", false);
            }
        },

        async login({ commit }, credentials) {
            try {
                commit("SET_LOADING", true);
                commit("SET_ERROR", null);
                const response = await api.post("/auth/login", credentials);
                commit("SET_TOKEN", response.data.token);
                commit("SET_USER", response.data.user);
                return response.data;
            } catch (error) {
                commit(
                    "SET_ERROR",
                    error.response?.data?.error || "Login failed"
                );
                throw error;
            } finally {
                commit("SET_LOADING", false);
            }
        },
        async initiateGoogleLogin() {
            const response = await api.post("/auth/google/login");
            window.location.href = response.data.url;
        },
        async handleGoogleCallback({ dispatch }, code) {
            const response = await api.post("/auth/google/callback", {
                code: code,
            });
            localStorage.setItem("token", response.data.token);
            await dispatch("checkAuthStatus");
            return response.data.token;
        },
        async checkAuthStatus({ commit }) {
            const token = localStorage.getItem("token");
            if (!token) {
                commit("SET_AUTH_STATUS", false);
                commit("SET_USER", null);
                return false;
            }
            try {
                const response = await api.get("/auth/status");
                commit("SET_AUTH_STATUS", true);
                commit("SET_USER", response.data.user);
                return true;
            } catch (error) {
                commit("SET_AUTH_STATUS", false);
                commit("SET_USER", null);
                localStorage.removeItem("token");
                return false;
            }
        },
        logout({ commit }) {
            localStorage.removeItem("token");
            commit("SET_AUTH_STATUS", false);
            commit("SET_USER", null);
        },
    },
};
