<template>
    <div class="h-full flex items-center justify-center bg-gradient-to-br from-gray-900 to-gray-800 p-8">
        <!-- Decorative blobs with pointer-events-none -->
        <div class="fixed inset-0 overflow-hidden pointer-events-none">
            <div
                class="absolute top-0 left-0 -translate-x-1/2 -translate-y-1/2 w-64 h-64 bg-blue-500 rounded-full mix-blend-multiply filter blur-3xl opacity-5 animate-blob">
            </div>
            <div
                class="absolute top-0 right-0 translate-x-1/2 -translate-y-1/2 w-64 h-64 bg-purple-500 rounded-full mix-blend-multiply filter blur-3xl opacity-5 animate-blob animation-delay-2000">
            </div>
            <div
                class="absolute -bottom-8 left-20 w-64 h-64 bg-pink-500 rounded-full mix-blend-multiply filter blur-3xl opacity-5 animate-blob animation-delay-4000">
            </div>
        </div>

        <!-- Main content with higher z-index -->
        <div
            class="w-full max-w-md px-8 py-10 bg-gradient-to-br from-gray-800 to-gray-900 rounded-lg shadow-2xl transform transition-all duration-300 border border-gray-700 relative z-10">
            <h2 class="mb-8 text-3xl font-bold text-center text-white">Welcome Back</h2>
            <div class="space-y-6">
                <button @click="handleGoogleLogin"
                    class="w-full px-4 py-3 flex items-center justify-center space-x-2 bg-gradient-to-br from-gray-800 to-gray-900 border border-gray-600 rounded-lg shadow-lg hover:from-gray-700 hover:to-gray-800 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-800 transition-all duration-300 transform hover:scale-105">
                    <svg class="w-5 h-5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
                            fill="#4285F4" />
                        <path
                            d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
                            fill="#34A853" />
                        <path
                            d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
                            fill="#FBBC05" />
                        <path
                            d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
                            fill="#EA4335" />
                    </svg>
                    <span class="text-sm font-medium text-gray-300">Login with Google</span>
                </button>

                <div class="relative">
                    <div class="absolute inset-0 flex items-center">
                        <div class="w-full border-t border-gray-700"></div>
                    </div>
                    <div class="relative flex justify-center text-sm">
                        <span class="px-2 bg-gradient-to-br from-gray-800 to-gray-900 text-gray-400">Or continue
                            with</span>
                    </div>
                </div>

                <div class="mt-6">
                    <router-link to="/login-basic"
                        class="w-full flex justify-center py-3 px-4 border border-transparent rounded-lg shadow-lg text-sm font-medium text-white bg-gradient-to-r from-blue-500 to-purple-600 hover:from-blue-600 hover:to-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-blue-500 transition-all duration-300 transform hover:scale-105">
                        Sign in with email
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, ref, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'

export default {
    name: 'LoginPage',
    setup() {
        const store = useStore()
        const router = useRouter()
        const route = useRoute()

        const loading = computed(() => store.state.auth.loading)
        const error = computed(() => store.state.auth.error)

        const handleGoogleLogin = () => {
            store.dispatch('auth/initiateGoogleLogin')
        }

        onMounted(async () => {
            const code = route.query.code
            if (code) {
                try {
                    await store.dispatch('auth/handleGoogleCallback', code)
                    router.push('/')
                } catch (error) {
                    console.error('Failed to handle Google callback:', error)
                }
            }
        })

        return {
            handleGoogleLogin,
            loading,
            error,
        }
    }
}
</script>

<style scoped>
.animate-blob {
    animation: blob 7s infinite;
}

.animation-delay-2000 {
    animation-delay: 2s;
}

.animation-delay-4000 {
    animation-delay: 4s;
}

@keyframes blob {
    0% {
        transform: translate(-1%, -1%) scale(1);
    }

    33% {
        transform: translate(1%, 1%) scale(1.1);
    }

    66% {
        transform: translate(-1%, 1%) scale(0.9);
    }

    100% {
        transform: translate(-1%, -1%) scale(1);
    }
}
</style>