<script setup>
import { computed } from 'vue'
import { cn } from '@/lib/utils'
import { useVModel } from '@vueuse/core'

const props = defineProps({
    class: {
        type: [String, Object, Array],
        default: ''
    },
    defaultValue: {
        type: [String, Number],
        default: ''
    },
    modelValue: {
        type: [String, Number],
        default: undefined
    }
})

const emit = defineEmits(['update:modelValue'])

const modelValue = useVModel(props, 'modelValue', emit, {
    passive: true,
    defaultValue: props.defaultValue,
})

const classes = computed(() =>
    cn(
        'lg:w-full p-3 border border-gray-600 rounded-lg w-full  bg-gray-700 text-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300 min-h-20',
        props.class
    )
)
</script>

<template>
    <textarea v-model="modelValue" :class="classes" />
</template>