import api from "@/services/api";

export default {
    namespaced: true,
    state: {
        isSubscribed: false,
        user: null,
    },
    mutations: {
        SET_SUBSCRIPTION_STATUS(state, status) {
            state.isSubscribed = status;
        },
    },
    getters: {
        isSubscribed: (state) => state.isSubscribed,
        user: (state) => state.user,
    },
    actions: {
        async cancel({ commit }) {
            const response = await api.post("/subscriptions/cancel");
            return response.data;
        },

        async resume({ commit }) {
            const response = await api.post("/subscriptions/resume");
            return response.data;
        },
        async createCheckoutSession({ commit }, payload) {
            try {
                const response = await api.post("/create-checkout-session", {
                    priceId: payload.priceId,
                    isOneTime: payload.isOneTime,
                    invoiceId: payload.invoiceId,
                    planName: payload.planName,
                });

                if (response.data.error) {
                    throw new Error(response.data.error);
                }

                return response.data.url;
            } catch (error) {
                console.error("Checkout session creation error:", error);
                throw error;
            }
        },
        async confirmSubscription({ commit }, { sessionId, userId }) {
            try {
                const response = await api.post("/confirm-subscription", {
                    sessionId,
                    userId,
                });
                commit("SET_SUBSCRIPTION_STATUS", true);
                return response.data;
            } catch (error) {
                console.error("Error confirming subscription:", error);
                throw error;
            }
        },
        async checkSubscription({ commit }) {
            try {
                const response = await api.get("/check-subscription");
                commit("SET_SUBSCRIPTION_STATUS", response.data.subscribed);
                return response.data.subscribed;
            } catch (error) {
                console.error("Failed to check subscription:", error);
                return false;
            }
        },
    },
};
