<template>
    <div class="p-3 bg-gray-700 border border-gray-600 rounded-lg bg-gray-700">
        <div class="flex justify-between items-center text-gray-200">
            <button @click="navigate(-1)" class="p-1.5 hover:bg-gray-600 rounded-md transition-colors duration-200"
                type="button">
                <ChevronLeft class="h-4 w-4" />
            </button>
            <div class="text-sm font-medium">{{ formatMonth(currentMonth) }}</div>
            <button @click="navigate(1)" class="p-1.5 hover:bg-gray-600 rounded-md transition-colors duration-200"
                type="button">
                <ChevronRight class="h-4 w-4" />
            </button>
        </div>
        <div class="grid grid-cols-7 mt-4">
            <span v-for="day in ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa']" :key="day"
                class="text-center text-xs text-gray-400 font-medium">
                {{ day }}
            </span>
        </div>
        <div class="grid grid-cols-7 mt-2 gap-1">
            <button v-for="date in weeks" :key="date.toISOString()" @click="selectDate(date)" type="button"
                :disabled="isDisabled(date)" :class="[
                    'p-2 text-sm rounded-md transition-colors duration-200',
                    !isSameMonth(date, currentMonth) && 'text-gray-600',
                    isDisabled(date) ? 'text-gray-600 cursor-not-allowed' : 'hover:bg-gray-600',
                    isEqual(date, selected) && 'bg-blue-500 text-white hover:bg-blue-600',
                    isToday(date) && !isEqual(date, selected) && 'border border-blue-500 text-gray-200',
                    isSameMonth(date, currentMonth) && !isEqual(date, selected) && !isToday(date) && 'text-gray-200'
                ]">
                {{ formatDate(date) }}
            </button>
        </div>
    </div>
</template>

<script>
import { ChevronLeft, ChevronRight } from 'lucide-vue-next'
import { getDaysInMonth, startOfMonth, endOfMonth, startOfWeek, endOfWeek, eachDayOfInterval, format, isToday, isSameMonth, isEqual } from 'date-fns'

export default {
    components: {
        ChevronLeft,
        ChevronRight
    },
    props: {
        selected: Date,
        month: Date,
        disabled: Object
    },
    data() {
        return {
            currentMonth: this.month || new Date()
        }
    },
    computed: {
        weeks() {
            const start = startOfWeek(startOfMonth(this.currentMonth))
            const end = endOfWeek(endOfMonth(this.currentMonth))
            return eachDayOfInterval({ start, end })
        }
    },
    methods: {
        navigate(delta) {
            const newMonth = new Date(this.currentMonth.setMonth(this.currentMonth.getMonth() + delta))
            this.currentMonth = newMonth
            this.$emit('update:month', newMonth)
        },
        selectDate(date) {
            this.$emit('update:selected', date)
        },
        isDisabled(date) {
            if (!this.disabled) return false
            const { from, to } = this.disabled
            if (from && date < from) return true
            if (to && date > to) return true
            return false
        },
        formatMonth(date) {
            return format(date, 'MMMM yyyy')
        },
        formatDate(date) {
            return format(date, 'd')
        },
        isSameMonth,
        isEqual,
        isToday
    }
}
</script>