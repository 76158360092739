<template>
    <div class="absolute inset-0 bg-gradient-to-br from-gray-900 to-gray-800 overflow-hidden">
        <div class="relative h-full flex flex-col sm:flex-row">
            <!-- Loading State -->
            <div v-if="isLoading"
                class="absolute inset-0 flex items-center justify-center z-20 bg-gray-800 bg-opacity-75">
                <svg class="animate-spin h-8 w-8 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
                    viewBox="0 0 24 24">
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path class="opacity-75" fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                    </path>
                </svg>
            </div>

            <!-- Main Content -->
            <div class="absolute inset-0 flex w-full h-full flex-col sm:flex-row">
                <!-- Step 1: Invoice Form -->
                <div class="flex transition-transform duration-500 ease-in-out w-full h-full"
                    :class="[currentStep === 1 ? 'translate-x-0' : '-translate-x-full']">
                    <!-- Form Section -->
                    <div class="flex-1 border-r border-gray-700 overflow-y-auto h-full p-4 sm:p-8">
                        <InvoiceForm ref="invoiceFormRef" :is-subscribed="isSubscribed"
                            @form-valid="handleFormValidation" :stored-form-data="storedFormData" />

                        <!-- Mobile-only Next Step button -->
                        <div class="sm:hidden mt-4 px-8">
                            <button @click="handleNextStep" :disabled="!isFormValid"
                                class="w-full flex justify-center items-center gap-2 bg-gradient-to-r from-blue-500 to-purple-600 hover:from-blue-600 hover:to-purple-700 text-white px-6 py-3 rounded-lg transition-all duration-200 font-medium"
                                :class="[
                                    isFormValid ? 'text-white' : 'bg-gray-700 text-gray-400 cursor-not-allowed'
                                ]">
                                Next Step
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20"
                                    fill="currentColor">
                                    <path fill-rule="evenodd"
                                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                        clip-rule="evenodd" />
                                </svg>
                            </button>
                        </div>
                    </div>

                    <!-- Info Section -->
                    <div
                        class="flex-1 flex h-full items-start flex-col p-4 sm:p-8 bg-gradient-to-br from-gray-800 to-gray-900 hidden sm:flex">
                        <div class="space-y-4 sm:space-y-8">
                            <div
                                class="h-16 w-16 bg-blue-500 rounded-2xl flex items-center justify-center shadow-lg text-white">
                                <svg viewBox="0 0 24 24" class="h-8 w-8" fill=" none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                        d="M6 2h12a2 2 0 012 2v16a2 2 0 01-2 2H6a2 2 0 01-2-2V4a2 2 0 012-2z"
                                        stroke="currentColor" />
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                        d="M8 7h8M8 12h8M8 17h5" stroke="currentColor" />
                                    <circle cx="17" cy="17" r="1" fill="currentColor" />
                                </svg>
                            </div>
                            <h2 class="text-2xl sm:text-4xl font-bold text-white leading-tight">First, let's handle the
                                essentials</h2>
                            <p class="text-base sm:text-xl text-gray-300 leading-relaxed">
                                Take care of the important details first. A well-structured invoice helps maintain
                                professional relationships and ensures smooth business operations.
                            </p>
                        </div>

                        <div class="w-full flex justify-end">
                            <button @click="handleNextStep" :disabled="!isFormValid"
                                class="bg-gradient-to-r from-blue-500 to-purple-600 hover:from-blue-600 hover:to-purple-700 text-white font-medium py-3 px-6 rounded-xl flex items-center gap-2 transition-colors duration-200"
                                :class="[
                                    isFormValid ? 'text-white' : 'bg-gray-700 text-gray-400 cursor-not-allowed'
                                ]">
                                Next Step
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20"
                                    fill="currentColor">
                                    <path fill-rule="evenodd"
                                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                        clip-rule="evenodd" />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>

                <!-- Step 2: Template Selection -->
                <div class="absolute inset-0 flex transition-transform duration-500 ease-in-out w-full h-full"
                    :class="[currentStep === 2 ? 'translate-x-0' : 'translate-x-full']">
                    <!-- Template Grid Section -->
                    <div class="flex-1 relative overflow-y-auto h-full p-4 sm:p-8">
                        <TemplateGrid ref="templateGridRef" :selected-template-id="selectedTemplate?.id"
                            @preview-template="showTemplatePreview" @select-template="handleTemplateSelection" />

                        <!-- Mobile-only buttons -->
                        <div
                            class="fixed sm:hidden bottom-0 left-0 right-0 p-4 bg-gradient-to-t from-gray-900 via-gray-900/95 to-transparent">
                            <div class="flex gap-3">
                                <button @click="goToStep(1)"
                                    class="flex-shrink-0 bg-gray-700 hover:bg-gray-600 text-white font-medium py-3 px-4 rounded-xl flex items-center justify-center gap-2 transition-colors duration-200">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 rotate-180"
                                        viewBox="0 0 20 20" fill="currentColor">
                                        <path fill-rule="evenodd"
                                            d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                            clip-rule="evenodd" />
                                    </svg>
                                    Back
                                </button>

                                <button @click="generateInvoice" :disabled="!selectedTemplate"
                                    class="flex-1 bg-gradient-to-r from-blue-500 to-purple-600 hover:from-blue-600 hover:to-purple-700 text-white font-medium py-3 px-4 rounded-xl flex items-center justify-center gap-2 transition-all duration-200"
                                    :class="{ 'opacity-50 cursor-not-allowed': !selectedTemplate }">
                                    Generate Invoice
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20"
                                        fill="currentColor">
                                        <path fill-rule="evenodd"
                                            d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                            clip-rule="evenodd" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>

                    <!-- Info Section -->
                    <div
                        class="flex-1 flex flex-col justify-between p-4 sm:p-8 bg-gradient-to-br from-gray-800 to-gray-900 h-full hidden sm:flex">
                        <div class="space-y-4 sm:space-y-6">
                            <div class="h-16 w-16 bg-purple-500 rounded-2xl flex items-center justify-center">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 text-white" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                        d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                                </svg>
                            </div>
                            <h2 class="text-2xl sm:text-3xl font-bold text-white">Now, let's make it stand out</h2>
                            <p class="text-base sm:text-xl text-gray-300">
                                Choose a template that best represents your brand. A well-designed invoice not only
                                looks professional but also enhances your business image.
                            </p>

                            <div class="flex items-center justify-between">
                                <button @click="goToStep(1)"
                                    class="bg-gray-700 hover:bg-gray-600 text-white font-medium py-3 px-6 rounded-xl flex items-center gap-2 transition-colors duration-200">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 rotate-180"
                                        viewBox="0 0 20 20" fill="currentColor">
                                        <path fill-rule="evenodd"
                                            d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                            clip-rule="evenodd" />
                                    </svg>
                                    Step Back
                                </button>

                                <button @click="generateInvoice" :disabled="!selectedTemplate"
                                    class="bg-gradient-to-r from-blue-500 to-purple-600 hover:from-blue-600 hover:to-purple-700 text-white font-medium py-3 px-6 rounded-xl flex items-center gap-2 transition-colors duration-200">
                                    Generate Invoice
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20"
                                        fill="currentColor">
                                        <path fill-rule="evenodd"
                                            d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                            clip-rule="evenodd" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modals -->
        <PreviewModal v-if="previewedTemplate" :show="showTemplatePreviewModal" :template="previewedTemplate"
            @close="closeTemplatePreview" @select="handleTemplateSelection" />
        <PaymentModal v-if="showPaymentModal" @close="showPaymentModal = false"
            @payment-completed="handlePaymentCompleted" :invoice-id="invoiceId" />
    </div>
</template>

<script setup>
import { ref, nextTick, onMounted, computed } from 'vue'
import { useStore } from 'vuex'
import { v4 as uuidv4 } from 'uuid'
import { useToast } from 'vue-toastification'
import InvoiceForm from '@/components/InvoiceForm.vue'
import TemplateGrid from '@/components/TemplateGrid.vue'
import PreviewModal from '@/components/PreviewModal.vue'
import PaymentModal from '@/components/PaymentModal.vue'
import api from '@/services/api'
import router from '@/router'

const toast = useToast()
const store = useStore()
const currentStep = ref(1)
const isFormValid = ref(false)
const selectedTemplate = ref(null)
const previewedTemplate = ref(null)
const showTemplatePreviewModal = ref(false)
const showPaymentModal = ref(false)
const isLoading = ref(false)
const invoiceId = ref(null)
const storedFormData = ref(null)

const invoiceFormRef = ref(null)
const templateGridRef = ref(null)

const isSubscribed = computed(() => store.getters['subscription/isSubscribed'])

const emit = defineEmits(['invoice-created'])

const handleFormValidation = (isValid) => {
    isFormValid.value = isValid
}

const handleNextStep = async () => {
    if (!isFormValid.value) {
        toast.error('Please complete all required fields')
        return
    }

    storedFormData.value = await invoiceFormRef.value?.getFormData()
    goToStep(2)
}

const goToStep = async (step) => {
    currentStep.value = step

    if (step === 1) {
        await nextTick()
        if (storedFormData.value && invoiceFormRef.value) {
            invoiceFormRef.value.restoreFormData(storedFormData.value)
        }
    }
}

const handleTemplateSelection = (template) => {
    selectedTemplate.value = template
    closeTemplatePreview()
}

const showTemplatePreview = (template) => {
    previewedTemplate.value = template
    showTemplatePreviewModal.value = true
}

const closeTemplatePreview = () => {
    showTemplatePreviewModal.value = false
    previewedTemplate.value = null
}

const generateInvoice = async () => {
    if (!selectedTemplate.value) {
        toast.error('Please select a template first.')
        return
    }

    if (!storedFormData.value) {
        toast.error('Please complete the form first.')
        return
    }

    isLoading.value = true

    try {
        const formData = new FormData()
        const invoice = {
            ...storedFormData.value,
            templateName: selectedTemplate.value.name,
            templateComponentName: selectedTemplate.value.componentName,
            status: "Draft"
        }

        formData.append("invoice", JSON.stringify(invoice))

        if (storedFormData.value.logo) {
            let logoBlob
            let filename

            if (storedFormData.value.logo.startsWith('data:image')) {
                const response = await fetch(storedFormData.value.logo)
                logoBlob = await response.blob()
                const fileType = storedFormData.value.logo.split(';')[0].split('/')[1]
                filename = `logo_${uuidv4()}.${fileType}`
            } else if (storedFormData.value.logo instanceof File) {
                logoBlob = storedFormData.value.logo
                const fileExt = logoBlob.name.split('.').pop()
                filename = `logo_${uuidv4()}.${fileExt}`
            }

            if (logoBlob && filename) {
                formData.append('logo', logoBlob, filename)
            }
        }

        const response = await api.post('/invoices', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })

        emit('invoice-created', response.data)

        if (!isSubscribed.value) {
            invoiceId.value = response.data.ID
            showPaymentModal.value = true
        } else {
            toast.success('Invoice generated successfully!')
            router.push("/")
        }

    } catch (error) {
        console.error('Error generating invoice:', error)
        toast.error('Failed to generate invoice. Please try again.')
    } finally {
        isLoading.value = false
    }
}

const handlePaymentCompleted = () => {
    showPaymentModal.value = false
    toast.success('Invoice generated successfully!')
}

onMounted(async () => {
    if (storedFormData.value && invoiceFormRef.value) {
        await nextTick()
        invoiceFormRef.value.restoreFormData(storedFormData.value)
    }
})
</script>

<style scoped>
.list-enter-active,
.list-leave-active {
    transition: all 0.3s ease;
}

.list-enter-from {
    opacity: 0;
    transform: translateY(-30px);
}

.list-leave-to {
    opacity: 0;
    transform: translateY(30px);
}

.list-move {
    transition: transform 0.3s ease;
}

.cursor-not-allowed {
    opacity: .3;
}
</style>