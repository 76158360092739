<template>
    <!-- Grid container with consistent padding and spacing -->
    <div class="p-8 sm:pb-8 space-y-8">
        <!-- Title and description -->
        <div class="space-y-2">
            <h2 class="text-2xl font-semibold text-white">Choose a Template</h2>
            <p class="text-gray-300">Select a template that best matches your needs.</p>
        </div>

        <!-- Grid layout with consistent spacing -->
        <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
            <!-- Template Card -->
            <div v-for="template in templates" :key="template.id" @click="previewTemplate(template)"
                class="relative group overflow-hidden rounded-xl shadow-lg transition-all duration-300 cursor-pointer active:scale-95 touch-manipulation bg-gray-800/50"
                :class="{ 'selected-template': selectedTemplateId === template.id }">
                <!-- Template Image -->
                <div class="aspect-w-16 aspect-h-9">
                    <img :src="template.thumbnail" :alt="template.name" class="w-full h-full object-cover"
                        loading="lazy">
                </div>

                <!-- Overlay -->
                <div
                    class="absolute inset-0 bg-gradient-to-t from-gray-900 via-gray-900/80 to-transparent opacity-100 sm:opacity-0 sm:group-hover:opacity-80 transition-opacity duration-300">
                </div>

                <!-- Selected state indicator -->
                <div v-if="selectedTemplateId === template.id"
                    class="absolute inset-0 bg-gradient-to-br from-blue-500/10 to-purple-600/10">
                    <div
                        class="absolute top-4 right-4 bg-gradient-to-r from-blue-500 to-purple-600 p-2 rounded-xl shadow-lg">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-white" viewBox="0 0 20 20"
                            fill="currentColor">
                            <path fill-rule="evenodd"
                                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                clip-rule="evenodd" />
                        </svg>
                    </div>
                </div>

                <!-- Info section -->
                <div class="absolute bottom-0 left-0 right-0 p-6 text-white bg-gradient-to-t from-gray-900 to-transparent"
                    :class="{
                        'sm:transform sm:translate-y-full sm:group-hover:translate-y-0': !selectedTemplateId === template.id,
                        'sm:!translate-y-0': selectedTemplateId === template.id
                    }">
                    <h3 class="text-lg font-semibold mb-2">{{ template.name }}</h3>
                    <p class="text-sm text-gray-300 line-clamp-2">{{ template.description }}</p>
                </div>

                <!-- Touch feedback overlay -->
                <div
                    class="absolute inset-0 bg-white opacity-0 transition-opacity duration-100 active:opacity-10 sm:hidden">
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, watch } from 'vue'
import { templates } from '@/templates';

const props = defineProps({
    selectedTemplateId: {
        type: [String, Number],
        default: null
    }
})

// Using a local ref to track internal selection state
const internalSelectedId = ref(props.selectedTemplateId)

// Watch for prop changes
watch(() => props.selectedTemplateId, (newId) => {
    internalSelectedId.value = newId
})

const emit = defineEmits(['preview-template', 'select-template'])

const previewTemplate = (template) => {
    emit('preview-template', template)
}

// Modified selection handler
const selectTemplate = (template) => {
    internalSelectedId.value = template.id
    emit('select-template', template)
}

defineExpose({
    selectTemplate
})
</script>