<template>
    <div class="h-full flex items-center justify-center bg-gray-900 py-12 px-4 sm:px-6 lg:px-8">
        <div class="max-w-md w-full space-y-8 w-full py-15  space-y-6 max-w-md px-8 py-10 bg-gradient-to-br from-gray-800 to-gray-900 rounded-lg shadow-2xl transform transition-all duration-300 border border-gray-700 relative z-10">
            <div>
                <h2 class="mt-6 text-center text-3xl font-extrabold text-white">
                    Verify Your Email
                </h2>
            </div>

            <div v-if="success" class="text-green-400 text-center mb-6">
                {{ success }}
            </div>

            <div v-else>
                <form class="mt-8 space-y-6" @submit.prevent="handleSubmit">
                    <div class="rounded-md shadow-sm space-y-4">
                        <div class="space-y-2">
                            <label for="email" class="block text-gray-400">Email address</label>
                            <input id="email" v-model="form.email" type="email" required
                                class="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-600 bg-gray-700 placeholder-gray-500 text-white focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10"
                                placeholder="Email address">
                        </div>
                    </div>

                    <div v-if="error" class="text-red-500 text-sm text-center">
                        {{ error }}
                    </div>

                    <div>
                        <button type="submit" :disabled="loading"
                            class="group relative w-full flex justify-center py-2 px-4 border border-transparent font-medium rounded-xl text-white bg-gradient-to-r from-blue-500 to-purple-600 hover:from-blue-600 hover:to-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                            <span v-if="loading">Verifying...</span>
                            <span v-else>Verify Email</span>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'

export default {
    name: 'VerifyEmailPage',
    setup() {
        const store = useStore()
        const router = useRouter()
        const route = useRoute()

        const form = ref({
            email: '',
            token: route.query.token || ''
        })

        const loading = computed(() => store.state.auth.loading)
        const error = computed(() => store.state.auth.error)
        const success = computed(() => store.state.auth.success)

        const handleSubmit = async () => {
            console.log(form.value.token)
            try {
                await store.dispatch('auth/verifyEmail', form.value)
                router.push('/')
            } catch (err) {
                console.error('Verification failed:', err)
            }
        }

        return {
            loading,
            error,
            success,
            handleSubmit,
            form
        }
    }
}
</script>