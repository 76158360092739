<script setup>
import { useScroll } from '@/composables/useScroll';

const props = defineProps({
    className: {
        type: String,
        default: ''
    }
})

const { scrollAreaRef, scrollBarRef, handleScroll } = useScroll()
</script>

<template>
    <div ref="scrollAreaRef" class="overflow-auto" @scroll="handleScroll" :class="props.className">
        <slot />
    </div>
</template>
