import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";
import LoginPage from "../views/LoginPage.vue";
import GoogleCallback from "../views/GoogleCallback.vue";
import InvoiceCreatePage from "@/views/InvoiceCreatePage.vue";
import SubscriptionPlans from "@/views/SubscriptionPlans.vue";
import SubscriptionSuccessfull from "@/views/SubscriptionSuccess.vue";
import DashboardPage from "@/views/DashboardPage.vue";
import Profile from "@/views/Profile.vue";
import Signup from "@/views/Signup.vue";
import LoginMail from "@/views/LoginMail.vue";
import VerifyAccount from "@/views/VerifyAccount.vue";

const routes = [
    {
        path: "/",
        name: "Dashboard",
        component: DashboardPage,
        meta: {
            requiresAuth: true,
            title: "Dashboard - PayMeMaybe",
            description:
                "Access your dashboard and manage your invoices with ease.",
        },
    },
    {
        path: "/login",
        name: "Login",
        component: LoginPage,
        meta: {
            title: "Login - PayMeMaybe",
            description:
                "Log in to your PayMeMaybe account to access your dashboard.",
        },
    },
    {
        path: "/login-basic",
        name: "LoginBasic",
        component: LoginMail,
        meta: {
            title: "Login - PayMeMaybe",
            description:
                "Log in to your PayMeMaybe account to access your dashboard.",
        },
    },
    {
        path: "/signup",
        name: "Signup",
        component: Signup,
        meta: {
            title: "Signup - PayMeMaybe",
            description: "Create your PayMeMaybe account now.",
        },
    },
    {
        path: "/verify-email",
        name: "VerifyAccount",
        component: VerifyAccount,
        meta: {
            title: "Verify Account - PayMeMaybe",
            description: "Verify your PayMeMaybe account now.",
        },
    },
    {
        path: "/profile",
        name: "Profile",
        component: Profile,
        meta: {
            requiresAuth: true,
            title: "Profile - PayMeMaybe",
            description: "View and edit your profile information.",
        },
    },
    {
        path: "/auth/google/callback",
        name: "GoogleCallback",
        component: GoogleCallback,
        meta: { title: "Google Authentication - PayMeMaybe" },
    },
    {
        path: "/invoice/create",
        name: "InvoiceCreate",
        component: InvoiceCreatePage,
        meta: {
            requiresAuth: true,
            title: "Create Invoice - PayMeMaybe",
            description: "Easily create a new invoice for your customers.",
        },
    },
    {
        path: "/subscription",
        name: "SubscriptionPlans",
        component: SubscriptionPlans,
        meta: {
            requiresAuth: true,
            title: "Subscription Plans - PayMeMaybe",
            description:
                "Explore subscription plans and choose one that fits your needs.",
        },
    },
    {
        path: "/success",
        name: "SubscriptionSuccessfull",
        component: SubscriptionSuccessfull,
        meta: {
            requiresAuth: true,
            title: "Subscription Success - PayMeMaybe",
            description:
                "Subscription successful! Enjoy access to premium features.",
        },
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

// Set metadata for each route using beforeEnter
router.beforeEach(async (to, from, next) => {
    const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
    const isAuthenticated = store.getters["auth/isAuthenticated"];

    if (requiresAuth && !isAuthenticated) {
        const token = localStorage.getItem("token");
        if (token) {
            try {
                // Check the authentication status
                await store.dispatch("auth/checkAuthStatus");

                // After checking auth status, check for subscription
                await store.dispatch("subscription/checkSubscription");

                if (store.getters["auth/isAuthenticated"]) {
                    next();
                    return;
                }
            } catch (error) {
                console.error("Token verification failed:", error);
            }
        }
        next({ name: "Login", query: { redirect: to.fullPath } });
    } else {
        // Check for subscription if already authenticated
        if (isAuthenticated) {
            await store.dispatch("subscription/checkSubscription");
        }
        next();
    }
});

// Set page title, description, and favicon when entering a route
router.afterEach((to) => {
    // Set document title
    document.title = to.meta.title || "PayMeMaybe";

    // Set meta description
    const descriptionMeta = document.querySelector('meta[name="description"]');
    if (descriptionMeta) {
        descriptionMeta.setAttribute(
            "content",
            to.meta.description || "A modern payment platform."
        );
    } else {
        const metaTag = document.createElement("meta");
        metaTag.setAttribute("name", "description");
        metaTag.setAttribute(
            "content",
            to.meta.description || "A modern payment platform."
        );
        document.head.appendChild(metaTag);
    }
});

export default router;
