import { createStore } from "vuex";
import auth from "./modules/auth";
import subscription from "./modules/subscription";
import invoice from "./modules/invoice";
import profile from "./modules/profile";

export default createStore({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        auth,
        subscription,
        invoice,
        profile,
    },
});
