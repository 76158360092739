import axios from "axios";
import api from "./services/api";

const fetchTemplates = async () => {
    try {
        const response = await api.get("/templates/get");
        console.log(response);
        const fetchedTemplates = response.data.map((template) => ({
            id: template.ID,
            name: template.TemplateName,
            componentName: template.TemplateComponentName,
            thumbnail: template.ThumbnailURL,
        }));

        return fetchedTemplates;
    } catch (error) {
        console.error("Error fetching templates:", error);
        return [];
    }
};

export const templates = await fetchTemplates();

export const getTemplateById = (id) => templates.find((t) => t.id === id);

export const getTemplateByComponentName = (componentName) =>
    templates.find((t) => t.componentName === componentName);
