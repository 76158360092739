<template>
    <div class="h-full bg-gradient-to-br from-gray-900 to-gray-800 flex items-center justify-center p-4">
        <div class="bg-gray-800 shadow-2xl rounded-lg p-8 max-w-md w-full border border-gray-700">
            <!-- Loading State -->
            <div v-if="!error && !confirmed" class="space-y-6">
                <h1 class="text-3xl font-light text-white mb-4">Confirming your subscription...</h1>
                <p class="text-gray-400">Please wait while we confirm your subscription.</p>
                <div class="flex justify-center py-4">
                    <div class="animate-spin rounded-full h-12 w-12 border-b-2 border-purple-500"></div>
                </div>
            </div>

            <!-- Success State -->
            <div v-if="confirmed" class="space-y-6">
                <div class="flex items-center justify-center">
                    <div class="bg-green-500/10 p-3 rounded-full">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12 text-green-500" fill="none"
                            viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                    </div>
                </div>
                <h1 class="text-3xl font-light text-green-400 text-center">Subscription Confirmed!</h1>
                <p class="text-gray-400 text-center">Your subscription has been successfully confirmed. You now have
                    access to all features!</p>
            </div>

            <!-- Error State -->
            <div v-if="error" class="space-y-6">
                <div class="flex items-center justify-center">
                    <div class="bg-red-500/10 p-3 rounded-full">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12 text-red-500" fill="none"
                            viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                    </div>
                </div>
                <h1 class="text-3xl font-light text-red-400 text-center">Error</h1>
                <p class="text-gray-400 text-center">{{ error }}</p>
            </div>

            <!-- Dashboard Button - Show only on success or error -->
            <button v-if="confirmed || error" @click="goToDashboard"
                class="w-full mt-8 bg-gradient-to-r from-blue-500 to-purple-600 text-white px-6 py-3 rounded-lg shadow-lg hover:from-blue-600 hover:to-purple-700 transition duration-300 transform hover:scale-[1.02] focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 focus:ring-offset-gray-800 font-semibold">
                Go to Dashboard
            </button>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { useToast } from "vue-toastification"

const router = useRouter()
const store = useStore()
const toast = useToast()
const error = ref('')
const confirmed = ref(false)

const confirmSubscription = async () => {
    const urlParams = new URLSearchParams(window.location.search)
    const sessionId = urlParams.get('session_id')
    const userId = urlParams.get('user_id')

    if (!sessionId || !userId) {
        error.value = 'Invalid URL parameters. Please try again or contact support.'
        toast.error("Invalid subscription confirmation URL")
        return
    }

    try {
        await store.dispatch('subscription/confirmSubscription', { sessionId, userId })
        confirmed.value = true
        toast.success("Subscription confirmed successfully!", {
            timeout: 5000
        })
    } catch (err) {
        console.error('Error confirming subscription:', err)
        error.value = 'Failed to confirm subscription. Please contact our support team for assistance.'
        toast.error("Failed to confirm subscription")
    }
}

const goToDashboard = () => {
    router.push('/')
}

onMounted(() => {
    confirmSubscription()
})
</script>

<style scoped>
.animate-spin {
    animation: spin 1s linear infinite;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}
</style>