<template>
    <div class="p-8 sm:pb-8 space-y-8">
        <CustomerSuggestion @customer-selected="fillCustomerDetails" />
        <!-- Logo Upload -->
        <div class="space-y-2">
            <Label for="logo" class="text-lg font-medium text-gray-300">Company Logo</Label>
            <div class="flex flex-col items-start space-y-4 md:flex-row md:items-center md:space-x-4 md:space-y-0">
                <img v-if="form.logo" :src="form.logo" alt="Company Logo"
                    class="w-16 h-16 object-contain rounded-lg shadow" />
                <Input id="logo" type="file" @change="handleLogoUpload" accept="image/*"
                    class="bg-gray-700 text-gray-200 file:mr-4 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-500 file:text-white hover:file:bg-blue-600" />
            </div>
        </div>

        <!-- User and Customer Information -->
        <div class="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-2">
            <div class="space-y-6">
                <h3 class="text-xl font-semibold text-white">Your Information</h3>

                <!-- User Name -->
                <div class="space-y-2">
                    <Input class="w-full bg-gray-700 text-gray-200" v-model="form.userName" label="Name"
                        placeholder="Your full name" :error="validateField('userName')"
                        @input="(e) => form.userName = sanitizeInput(e.target.value, 'name')"
                        @blur="touchField('userName')" />
                    <span v-if="validateField('userName')" class="text-sm text-red-400">
                        {{ validateField('userName') }}
                    </span>
                </div>

                <!-- User Email -->
                <div class="space-y-2">
                    <Input class="w-full bg-gray-700 text-gray-200" v-model="form.userEmail" label="Email" type="email"
                        placeholder="your@email.com" :error="validateField('userEmail')"
                        @blur="touchField('userEmail')" />
                    <span v-if="validateField('userEmail')" class="text-sm text-red-400">
                        {{ validateField('userEmail') }}
                    </span>
                </div>

                <!-- User Address -->
                <div class="space-y-2">
                    <Input class="w-full bg-gray-700 text-gray-200" v-model="form.userAddress" label="Address"
                        placeholder="Your address" :error="validateField('userAddress')"
                        @input="(e) => form.userAddress = sanitizeInput(e.target.value, 'address')"
                        @blur="touchField('userAddress')" />
                    <span v-if="validateField('userAddress')" class="text-sm text-red-400">
                        {{ validateField('userAddress') }}
                    </span>
                </div>

                <!-- User Company Name -->
                <div class="space-y-2">
                    <Input class="w-full bg-gray-700 text-gray-200" v-model="form.userCompanyName" label="Company Name"
                        placeholder="Your company" :error="validateField('userCompanyName')"
                        @input="(e) => form.userCompanyName = sanitizeInput(e.target.value, 'companyName')"
                        @blur="touchField('userCompanyName')" />
                    <span v-if="validateField('userCompanyName')" class="text-sm text-red-400">
                        {{ validateField('userCompanyName') }}
                    </span>
                </div>

                <!-- User Company VAT -->
                <div class="space-y-2">
                    <Input class="w-full bg-gray-700 text-gray-200" v-model="form.userCompanyVAT" label="Company VAT"
                        placeholder="VAT number" :error="validateField('userCompanyVAT')"
                        @input="(e) => form.userCompanyVAT = sanitizeInput(e.target.value, 'vat')"
                        @blur="touchField('userCompanyVAT')" />
                    <span v-if="validateField('userCompanyVAT')" class="text-sm text-red-400">
                        {{ validateField('userCompanyVAT') }}
                    </span>
                </div>
            </div>

            <div class="space-y-6">
                <h3 class="text-xl font-semibold text-white">Customer Information</h3>

                <!-- Customer Name -->
                <div class="space-y-2">
                    <Input class="w-full bg-gray-700 text-gray-200" v-model="form.customerName" label="Name"
                        placeholder="Customer's full name" :error="validateField('customerName')"
                        @input="(e) => form.customerName = sanitizeInput(e.target.value, 'name')"
                        @blur="touchField('customerName')" />
                    <span v-if="validateField('customerName')" class="text-sm text-red-400">
                        {{ validateField('customerName') }}
                    </span>
                </div>

                <!-- Customer Email -->
                <div class="space-y-2">
                    <Input class="w-full bg-gray-700 text-gray-200" v-model="form.customerEmail" label="Email"
                        type="email" placeholder="customer@email.com" :error="validateField('customerEmail')"
                        @blur="touchField('customerEmail')" />
                    <span v-if="validateField('customerEmail')" class="text-sm text-red-400">
                        {{ validateField('customerEmail') }}
                    </span>
                </div>

                <!-- Customer Address -->
                <div class="space-y-2">
                    <Input class="w-full bg-gray-700 text-gray-200" v-model="form.customerAddress" label="Address"
                        placeholder="Customer's address" :error="validateField('customerAddress')"
                        @input="(e) => form.customerAddress = sanitizeInput(e.target.value, 'address')"
                        @blur="touchField('customerAddress')" />
                    <span v-if="validateField('customerAddress')" class="text-sm text-red-400">
                        {{ validateField('customerAddress') }}
                    </span>
                </div>

                <!-- Customer Company Name -->
                <div class="space-y-2">
                    <Input class="w-full bg-gray-700 text-gray-200" v-model="form.customerCompanyName"
                        label="Company Name" placeholder="Customer's company"
                        :error="validateField('customerCompanyName')"
                        @input="(e) => form.customerCompanyName = sanitizeInput(e.target.value, 'companyName')"
                        @blur="touchField('customerCompanyName')" />
                    <span v-if="validateField('customerCompanyName')" class="text-sm text-red-400">
                        {{ validateField('customerCompanyName') }}
                    </span>
                </div>

                <!-- Customer Company VAT -->
                <div class="space-y-2">
                    <Input class="w-full bg-gray-700 text-gray-200" v-model="form.customerCompanyVAT"
                        label="Company VAT" placeholder="Customer's VAT number"
                        :error="validateField('customerCompanyVAT')"
                        @input="(e) => form.customerCompanyVAT = sanitizeInput(e.target.value, 'vat')"
                        @blur="touchField('customerCompanyVAT')" />
                    <span v-if="validateField('customerCompanyVAT')" class="text-sm text-red-400">
                        {{ validateField('customerCompanyVAT') }}
                    </span>
                </div>
            </div>
        </div>

        <!-- Invoice Details -->
        <div class="space-y-6">
            <h3 class="text-xl font-semibold text-white">Invoice Details</h3>
            <div class="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-2">
                <!-- Invoice Number -->
                <div class="space-y-2">
                    <Input class="w-full bg-gray-700 text-gray-200" v-model="form.invoiceNumber" label="Invoice Number"
                        placeholder="INV-001" :error="validateField('invoiceNumber')"
                        @input="(e) => form.invoiceNumber = sanitizeInput(e.target.value, 'invoiceNumber')"
                        @blur="touchField('invoiceNumber')" />
                    <span v-if="validateField('invoiceNumber')" class="text-sm text-red-400">
                        {{ validateField('invoiceNumber') }}
                    </span>
                </div>

                <!-- Currency -->
                <div class="space-y-2">
                    <Select class="w-full bg-gray-700 text-gray-200" v-model="form.currency" :options="form.currencies"
                        label="Currency" :error="validateField('currency')" @blur="touchField('currency')" />
                    <span v-if="validateField('currency')" class="text-sm text-red-400">
                        {{ validateField('currency') }}
                    </span>
                </div>

                <!-- Issue Date -->
                <div class="space-y-2">
                    <label class="text-sm text-gray-300">Issue Date</label>
                    <CalendarPicker class="w-full bg-gray-700 text-gray-200" v-model="form.issueDate"
                        :error="validateField('issueDate')" @blur="touchField('issueDate')" />
                    <span v-if="validateField('issueDate')" class="text-sm text-red-400">
                        {{ validateField('issueDate') }}
                    </span>
                </div>

                <!-- Due Date -->
                <div class="space-y-2">
                    <label class="text-sm text-gray-300">Due Date</label>
                    <CalendarPicker class="w-full bg-gray-700 text-gray-200" v-model="form.dueDate"
                        :error="validateField('dueDate')" @blur="touchField('dueDate')" />
                    <span v-if="validateField('dueDate')" class="text-sm text-red-400">
                        {{ validateField('dueDate') }}
                    </span>
                </div>
            </div>
        </div>

        <!-- Line Items -->
        <div class="space-y-4">
            <div class="flex items-center justify-between">
                <h3 class="text-xl font-semibold text-white">Invoice Items</h3>
                <span v-if="!hasItems && formSubmitAttempted" class="text-sm text-red-400">
                    At least one item is required
                </span>
            </div>
            <TransitionGroup name="list" tag="div" class="space-y-4">
                <div v-for="(item, index) in form.items" :key="item.id"
                    class="relative bg-gray-800/50 p-6 rounded-xl transition-all duration-300">
                    <button type="button" @click="removeLineItem(index)"
                        class="absolute top-4 right-4 text-gray-400 hover:text-red-400 transition duration-200">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none"
                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                            <path d="M18 6L6 18M6 6l12 12" />
                        </svg>
                    </button>

                    <div class="space-y-6">
                        <!-- Description -->
                        <div class="space-y-2">
                            <label class="block text-gray-400 text-base">Item description</label>
                            <Input class="w-full bg-gray-700/50 text-gray-200 rounded-xl border-0"
                                v-model="item.description" placeholder="Web development"
                                :error="validateItemField(index, 'description')"
                                @input="(e) => item.description = sanitizeInput(e.target.value, 'description')"
                                @blur="touchField(`items.${index}.description`)" />
                            <span v-if="validateItemField(index, 'description')" class="text-sm text-red-400">
                                {{ validateItemField(index, 'description') }}
                            </span>
                        </div>

                        <!-- Quantity -->
                        <div class="space-y-2">
                            <label class="block text-gray-400 text-base">Quantity</label>
                            <Input class="w-full bg-gray-700/50 text-gray-200 rounded-xl border-0"
                                v-model.number="item.quantity" type="number" min="1"
                                :error="validateItemField(index, 'quantity')"
                                @blur="touchField(`items.${index}.quantity`)" />
                            <span v-if="validateItemField(index, 'quantity')" class="text-sm text-red-400">
                                {{ validateItemField(index, 'quantity') }}
                            </span>
                        </div>

                        <!-- Price -->
                        <div class="space-y-2">
                            <label class="block text-gray-400 text-base">Price</label>
                            <Input class="w-full bg-gray-700/50 text-gray-200 rounded-xl border-0"
                                v-model.number="item.unitPrice" type="number" min="0" step="0.01"
                                :error="validateItemField(index, 'unitPrice')"
                                @blur="touchField(`items.${index}.unitPrice`)" />
                            <span v-if="validateItemField(index, 'unitPrice')" class="text-sm text-red-400">
                                {{ validateItemField(index, 'unitPrice') }}
                            </span>
                        </div>
                    </div>
                </div>
            </TransitionGroup>
            <div class="flex justify-center md:justify-start py-6">
                <button @click="addLineItem" type="button"
                    class="w-full flex justify-center items-center gap-2 bg-gradient-to-r from-blue-500 to-purple-600 hover:from-blue-600 hover:to-purple-700 text-white px-6 py-3 rounded-xl transition-all duration-200 font-medium">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="translate-y-[1px]">
                        <path d="M12 5v14M5 12h14" />
                    </svg>
                    Add Item
                </button>
            </div>
        </div>

        <!-- Tax Rate -->
        <div class="space-y-2">
            <h3 class="text-xl font-semibold text-white">Tax Rate</h3>
            <Input class="w-full bg-gray-700 text-gray-200" v-model.number="form.taxRate" label="Tax Rate %"
                type="number" min="0" max="100" step="0.01" :error="validateField('taxRate')"
                @blur="touchField('taxRate')" />
            <span v-if="validateField('taxRate')" class="text-sm text-red-400">
                {{ validateField('taxRate') }}
            </span>
        </div>

        <!-- Totals -->
        <div class="bg-gray-700 p-6 rounded-lg space-y-2">
            <div class="flex justify-between items-center text-lg">
                <span class="font-medium text-gray-300">Subtotal:</span>
                <span class="text-white">{{ formatCurrency(subtotal) }}</span>
            </div>
            <div class="flex justify-between items-center text-lg">
                <span class="font-medium text-gray-300">Tax ({{ form.taxRate }}%):</span>
                <span class="text-white">{{ formatCurrency(totalTax) }}</span>
            </div>
            <div class="flex justify-between items-center text-xl font-bold pt-2 border-t border-gray-600">
                <span class="text-white">Total:</span>
                <span class="text-white">{{ formatCurrency(total) }}</span>
            </div>
        </div>

        <!-- Notes and Terms -->
        <div class="space-y-4">
            <div class="space-y-2">
                <TextArea class="bg-gray-700 text-gray-200" v-model="form.notes" label="Notes" rows="3"
                    placeholder="Any additional notes..." :error="validateField('notes')"
                    @input="(e) => form.notes = sanitizeInput(e.target.value, 'longText')"
                    @blur="touchField('notes')" />
                <div class="flex justify-between">
                    <span v-if="validateField('notes')" class="text-sm text-red-400">
                        {{ validateField('notes') }}
                    </span>
                    <span class="text-sm text-gray-400">
                        {{ form.notes?.length || 0 }}/500
                    </span>
                </div>
            </div>
            <div class="space-y-2">
                <TextArea class="bg-gray-700 text-gray-200" v-model="form.termsAndConditions" label="Terms & Conditions"
                    rows="3" placeholder="Terms and conditions..." :error="validateField('termsAndConditions')"
                    @input="(e) => form.termsAndConditions = sanitizeInput(e.target.value, 'longText')"
                    @blur="touchField('termsAndConditions')" />
                <div class="flex justify-between">
                    <span v-if="validateField('termsAndConditions')" class="text-sm text-red-400">
                        {{ validateField('termsAndConditions') }}
                    </span>
                    <span class="text-sm text-gray-400">
                        {{ form.termsAndConditions?.length || 0 }}/1000
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, computed, watch, onMounted } from 'vue'
import { v4 as uuidv4 } from 'uuid'
import Input from "@/components/ui/Input.vue"
import Label from "@/components/ui/Label.vue"
import CalendarPicker from '@/components/CalendarPicker.vue'
import Select from "@/components/ui/Select.vue"
import TextArea from "@/components/ui/TextArea.vue"
import api from '@/services/api'
import { useFormValidation } from '@/composables/useFormValidation'
import CustomerSuggestion from './CustomerSuggestion.vue'
import { useToast } from 'vue-toastification'

const toast = useToast()

const props = defineProps({
    isSubscribed: {
        type: Boolean,
        default: false
    },
    storedFormData: {
        type: Object,
        default: null
    }
})

const emit = defineEmits(['form-valid'])

const form = ref({
    logo: null,
    userName: '',
    userEmail: '',
    userAddress: '',
    userCompanyName: '',
    userCompanyVAT: '',
    customerName: '',
    customerEmail: '',
    customerAddress: '',
    customerCompanyName: '',
    customerCompanyVAT: '',
    invoiceNumber: '',
    taxRate: 0,
    currency: 'USD',
    currencies: [
        { label: 'USD', value: 'USD' },
        { label: 'EUR', value: 'EUR' },
        { label: 'GBP', value: 'GBP' }
    ],
    issueDate: new Date(),
    dueDate: new Date(),
    items: [{
        id: uuidv4(),
        description: '',
        quantity: 1,
        unitPrice: 0
    }],
    notes: '',
    termsAndConditions: ''
})


const {
    errors,
    isValid,
    validateField,
    validateItemField,
    sanitizeInput,
    touchField,
    resetTouchedFields,
    setFormSubmitted
} = useFormValidation(form)


const hasItems = computed(() => {
    return form.value.items.length > 0 && form.value.items.every(item =>
        item.description &&
        item.quantity > 0 &&
        item.unitPrice > 0
    )
})

// Complete form validation
const isFormComplete = computed(() => {
    return isValid.value && hasItems.value
})

// Watch validation state and emit changes
watch(() => isFormComplete.value, (newValue) => {
    emit('form-valid', newValue)
})

// Watch for stored form data changes
watch(() => props.storedFormData, (newValue) => {
    if (newValue) {
        restoreFormData(newValue)
    }
}, { immediate: true })

// Method to expose form data
const getFormData = () => {
    return {
        ...form.value,
        items: form.value.items.map(item => ({
            description: item.description,
            quantity: parseInt(item.quantity),
            unitPrice: parseFloat(item.unitPrice)
        })),
        amount: total.value
    }
}

// Restore form data method
const restoreFormData = (data) => {
    if (data) {
        // Restore main form fields
        Object.keys(data).forEach(key => {
            if (key in form.value && key !== 'items') {
                form.value[key] = data[key]
            }
        })

        form.value.items = (data.items || []).map(item => ({
            id: uuidv4(),
            description: item.description,
            quantity: item.quantity,
            unitPrice: item.unitPrice
        }))

        touchAllFields()
    }
}

const touchAllFields = () => {
    const fields = [
        'userName', 'userEmail', 'userAddress', 'userCompanyName', 'userCompanyVAT',
        'customerName', 'customerEmail', 'customerAddress', 'customerCompanyName',
        'customerCompanyVAT', 'invoiceNumber', 'currency', 'issueDate', 'dueDate', 'taxRate'
    ]

    fields.forEach(field => touchField(field))

    // Touch all items
    form.value.items.forEach((_, index) => {
        touchField(`items.${index}.description`)
        touchField(`items.${index}.quantity`)
        touchField(`items.${index}.unitPrice`)
    })
}

// Computed properties
const subtotal = computed(() => {
    return form.value.items.reduce((sum, item) => sum + (item.quantity * item.unitPrice || 0), 0)
})

const totalTax = computed(() => {
    return subtotal.value * (form.value.taxRate / 100)
})

const total = computed(() => {
    return subtotal.value + totalTax.value
})

// Form methods
const addLineItem = () => {
    form.value.items.push({
        id: uuidv4(),
        description: '',
        quantity: 1,
        unitPrice: 0
    })
    const newIndex = form.value.items.length - 1
    touchField(`items.${newIndex}.description`)
    touchField(`items.${newIndex}.quantity`)
    touchField(`items.${newIndex}.unitPrice`)
}

const removeLineItem = (index) => {
    form.value.items.splice(index, 1)
    if (form.value.items.length === 0) {
        touchField('items')
    }
}

const handleLogoUpload = (event) => {
    const file = event.target.files[0]
    if (file) {
        const reader = new FileReader()
        reader.onload = (e) => {
            form.value.logo = e.target.result
        }
        reader.readAsDataURL(file)
    }
}

const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: form.value.currency
    }).format(amount)
}

const fillCustomerDetails = (customer) => {
    form.value.customerName = customer.name
    form.value.customerEmail = customer.email
    form.value.customerAddress = customer.address
    form.value.customerCompanyName = customer.company_name
    form.value.customerCompanyVAT = customer.company_vat

    touchField('customerName')
    touchField('customerEmail')
    touchField('customerAddress')
    touchField('customerCompanyName')
    touchField('customerCompanyVAT')
}

// Load user settings
const userSettings = ref({})
const isLoadingSettings = ref(true)

const loadUserSettings = async () => {
    try {
        isLoadingSettings.value = true
        const response = await api.get('/settings')
        userSettings.value = response.data
        applyUserSettings()
    } catch (error) {
        console.error('Error loading user settings:', error)
    } finally {
        isLoadingSettings.value = false
    }
}

const applyUserSettings = () => {
    if (!props.storedFormData) {
        form.value.userName = userSettings.value.Name || ''
        form.value.userEmail = userSettings.value.Settings?.CompanyEmail || ''
        form.value.userAddress = userSettings.value.Settings?.CompanyAddress || ''
        form.value.userCompanyName = userSettings.value.Settings?.CompanyName || ''
        form.value.currency = userSettings.value.Settings?.DefaultCurrency || 'USD'
        form.value.userCompanyVAT = userSettings.value.Settings?.UserCompanyVAT || ''

        if (userSettings.value.DefaultDueDays) {
            const dueDate = new Date()
            dueDate.setDate(dueDate.getDate() + userSettings.value.DefaultDueDays)
            form.value.dueDate = dueDate.toISOString().split('T')[0]
        }

        if (userSettings.value.Settings?.LogoURL && !form.value.logo) {
            form.value.logo = userSettings.value.Settings.LogoURL
        }

        touchAllFields()
    }
}

onMounted(() => {
    loadUserSettings()
})

defineExpose({
    getFormData,
    isValid: isFormComplete,
    form,
    restoreFormData
})
</script>

<style scoped>
.list-enter-active,
.list-leave-active {
    transition: all 0.3s ease;
}

.list-enter-from {
    opacity: 0;
    transform: translateY(-30px);
}

.list-leave-to {
    opacity: 0;
    transform: translateY(30px);
}

.list-move {
    transition: transform 0.3s ease;
}
</style>