import api from "@/services/api";

const state = {
    profile: {},
    stats: {},
    recentActivities: [],
    topClients: [],
    recentInvoices: [],
};

const mutations = {
    SET_PROFILE(state, profile) {
        state.profile = profile;
    },
    SET_STATS(state, stats) {
        state.stats = stats;
    },
    SET_RECENT_ACTIVITIES(state, activities) {
        state.recentActivities = activities;
    },
    SET_TOP_CLIENTS(state, clients) {
        state.topClients = clients;
    },
    SET_RECENT_INVOICES(state, invoices) {
        state.recentInvoices = invoices;
    },
};

const actions = {
    async fetchProfile({ commit }) {
        try {
            const response = await api.get("/settings/profile");
            commit("SET_PROFILE", response.data);
            return response.data;
        } catch (error) {
            console.error("Error fetching user profile:", error);
            throw error;
        }
    },
    async fetchStats({ commit }) {
        try {
            const response = await api.get("/settings/quick-stats");
            commit("SET_STATS", response.data);
            return response.data;
        } catch (error) {
            console.error("Error fetching stats:", error);
            throw error;
        }
    },
    async fetchRecentActivities({ commit }) {
        try {
            const response = await api.get("/activities/recent");
            commit("SET_RECENT_ACTIVITIES", response.data);
            return response.data;
        } catch (error) {
            console.error("Error fetching recent activities:", error);
            throw error;
        }
    },
    async fetchTopClients({ commit }) {
        try {
            const response = await api.get("/customers/top");
            commit("SET_TOP_CLIENTS", response.data);
            return response.data;
        } catch (error) {
            console.error("Error fetching top clients:", error);
            throw error;
        }
    },
    async fetchRecentInvoices({ commit }) {
        try {
            const response = await api.get("/invoices/recent");
            commit("SET_RECENT_INVOICES", response.data);
            return response.data;
        } catch (error) {
            console.error("Error fetching recent invoices:", error);
            throw error;
        }
    },
    async updateProfile({ commit }, profileData) {
        try {
            await api.put("/settings", profileData);
            commit("SET_PROFILE", profileData);
        } catch (error) {
            console.error("Error updating profile:", error);
            throw error;
        }
    },
    async upgradeSubscription() {
        try {
            await api.post("/subscription/upgrade");
        } catch (error) {
            console.error("Error upgrading subscription:", error);
            throw error;
        }
    },
    async cancelSubscription() {
        try {
            await api.post("/subscription/cancel");
        } catch (error) {
            console.error("Error cancelling subscription:", error);
            throw error;
        }
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
