<template>
    <div class="relative">
        <button type="button" :class="cn(
            'p-3 border border-gray-600 rounded-lg lg:w-full w-full bg-gray-700 text-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300 flex items-center text-left text-sm font-normal',
            { 'text-gray-400': !modelValue },
            props.class
        )" @click="toggleCalendar">
            <CalendarIcon class="mr-2 h-4 w-4" />
            {{ modelValue ? formatDate(modelValue) : 'Pick a date' }}
        </button>
        <div v-if="isOpen" class="absolute top-full mt-1 w-full z-50">
            <div class="w-[300px] bg-popover text-popover-foreground shadow-md bg-none border-none rounded-lg">
                <Calendar :selected="modelValue" @update:selected="updateDate"
                    :disabled="{ before: new Date(2000, 0, 1) }" />
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue'
import { format } from 'date-fns'
import { Calendar as CalendarIcon } from 'lucide-vue-next'
import Calendar from './ui/Calendar.vue'
import { cn } from '@/lib/utils'

const props = defineProps({
    modelValue: {
        type: Date,
        default: null
    },
    class: {
        type: String,
        default: ""
    }
})

const emit = defineEmits(['update:modelValue'])

const isOpen = ref(false)

const formatDate = (date) => {
    return format(date, 'PPP')
}

const toggleCalendar = () => {
    isOpen.value = !isOpen.value
}

const updateDate = (newDate) => {
    emit('update:modelValue', newDate)
    isOpen.value = false
}
</script>