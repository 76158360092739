<template>
    <div class="h-full flex items-center justify-center bg-gray-900 py-12 px-4 sm:px-6 lg:px-8">
        <div class="max-w-md w-full space-y-8">
            <form class="mt-8 space-y-6" @submit.prevent="handleSubmit">
                <div
                    class="w-full py-15  space-y-6 max-w-md px-8 py-10 bg-gradient-to-br from-gray-800 to-gray-900 rounded-lg shadow-2xl transform transition-all duration-300 border border-gray-700 relative z-10">
                    <h2 class="text-center text-3xl font-extrabold text-white">
                        Sign in to your account
                    </h2>
                    <div class="space-y-2">
                        <label for="email" class="block text-gray-400">Email address</label>
                        <input id="email" v-model="form.email" type="email" required
                            class="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-600 bg-gray-700 placeholder-gray-500 text-white focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                            placeholder="Email address">
                    </div>
                    <div class="space-y-2">
                        <label for="password" class="block text-gray-400">Password</label>
                        <input id="password" v-model="form.password" type="password" required
                            class="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-600 bg-gray-700 placeholder-gray-500 text-white focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                            placeholder="Password">
                    </div>
                    <div>
                        <button type="submit" :disabled="loading"
                            class="group relative w-full flex justify-center py-2 px-4 font-medium rounded-md text-white bg-gradient-to-r from-blue-500 to-purple-600 hover:from-blue-600 hover:to-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                            <span v-if="loading">Loading...</span>
                            <span v-else>Sign in</span>
                        </button>
                    </div>

                </div>

                <div v-if="error" class="text-red-500 text-sm text-center">
                    {{ error }}
                </div>


                <div class="text-sm text-center">
                    <router-link to="/signup" class="font-medium text-blue-400 hover:text-blue-300">
                        Don't have an account? Sign up
                    </router-link>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

export default {
    name: 'LoginForm',
    setup() {
        const store = useStore()
        const router = useRouter()

        const form = ref({
            email: '',
            password: ''
        })

        const loading = computed(() => store.state.auth.loading)
        const error = computed(() => store.state.auth.error)

        const handleSubmit = async () => {
            try {
                await store.dispatch('auth/login', form.value)
                router.push('/')
            } catch (err) {
                console.error('Login failed:', err)
            }
        }

        return {
            form,
            loading,
            error,
            handleSubmit
        }
    }
}
</script>