<template>
    <div class="fixed inset-0 bg-gray-900 bg-opacity-75 backdrop-blur-sm flex items-center justify-center p-4 z-50">
        <div v-if="invoice"
            class="bg-gradient-to-br from-gray-900 to-gray-800 rounded-lg shadow-2xl max-w-4xl w-full max-h-[90vh] overflow-y-auto border border-gray-700">
            <div class="p-6">
                <h2 class="text-2xl font-bold mb-4 text-white">Invoice Preview</h2>
                <div ref="invoiceContent" class="bg-white rounded-xl shadow-inner overflow-hidden">
                    <iframe v-if="renderedTemplate" :srcdoc="renderedTemplate" class="w-full h-[800px] border-0"
                        @load="iframeLoaded"></iframe>
                </div>
            </div>
            <div
                class="bg-gradient-to-br from-gray-800 to-gray-900 px-6 py-4 flex justify-end space-x-4 border-t border-gray-700">
                <button @click="$emit('close')"
                    class="bg-gradient-to-r from-gray-700 to-gray-800 text-white px-6 py-3 rounded-lg shadow-lg hover:from-gray-600 hover:to-gray-700 transition duration-300 transform hover:scale-105 border border-gray-600">
                    Close
                </button>
                <button @click="downloadInvoice(invoice)" :disabled="isDownloading"
                    class="bg-gradient-to-r from-blue-500 to-purple-600 text-white px-6 py-3 rounded-lg shadow-lg hover:from-blue-600 hover:to-purple-700 transition duration-300 transform hover:scale-105 disabled:opacity-50 disabled:transform-none disabled:hover:scale-100">
                    <div class="flex items-center gap-2">
                        <svg v-if="isDownloading" class="animate-spin h-5 w-5 text-white"
                            xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4" />
                            <path class="opacity-75" fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                        </svg>
                        <svg v-else viewBox="0 0 24 24" class="h-5 w-5" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 3V16M12 16L7 11M12 16L17 11" stroke="currentColor" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M20 21H4" stroke="currentColor" stroke-width="2" stroke-linecap="round" />
                        </svg>
                        {{ isDownloading ? 'Generating PDF...' : 'Download PDF' }}
                    </div>
                </button>
            </div>
        </div>
        <div v-else class="text-white text-lg flex items-center gap-3">
            <svg class="animate-spin h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4" />
                <path class="opacity-75" fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
            </svg>
            Loading invoice data...
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue';
import { useInvoiceDownload } from '@/composables/useInvoiceDownload';
import api from '@/services/api';
import Toast from '@/plugins/toast';

const props = defineProps({
    invoice: {
        type: Object,
        required: true
    },
});

const emit = defineEmits(['close']);
const invoiceContent = ref(null);
const renderedTemplate = ref(null);
const { isDownloading, downloadInvoice } = useInvoiceDownload();

function formatDate(dateString) {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
    });
}

// Fetch and render template
async function fetchAndRenderTemplate() {
    try {
        const response = await api.get(`/templates/${props.invoice.Template.ID}/html`)
        let templateHtml = await response.data;

        const data = {
            InvoiceNumber: props.invoice.InvoiceNumber,
            IssueDate: formatDate(props.invoice.IssueDate),
            DueDate: formatDate(props.invoice.DueDate),
            UserName: props.invoice.UserName,
            UserCompanyName: props.invoice.UserCompanyName,
            UserAddress: props.invoice.UserAddress,
            UserEmail: props.invoice.UserEmail,
            UserCompanyVAT: props.invoice.UserCompanyVAT,
            CustomerName: props.invoice.CustomerName,
            CustomerCompanyName: props.invoice.CustomerCompanyName,
            CustomerAddress: props.invoice.CustomerAddress,
            CustomerEmail: props.invoice.CustomerEmail,
            CustomerCompanyVAT: props.invoice.CustomerCompanyVAT,
            TermsAndConditions: props.invoice.TermsAndConditions,
            Notes: props.invoice.Notes,
            Items: props.invoice.Items,
            Currency: props.invoice.Currency,
            TaxRate: props.invoice.TaxRate,
            TotalAmount: props.invoice.Amount,
            Subtotal: props.invoice.Items.reduce((sum, item) => sum + (item.unitPrice * item.quantity), 0),
        };

        data.TaxAmount = data.Subtotal * (data.TaxRate / 100);

        Object.entries(data).forEach(([key, value]) => {
            if (Array.isArray(value)) {
                const itemsHtml = value.map(item => `
                    <tr>
                        <td>${item.description}</td>
                        <td>${item.quantity}</td>
                        <td>${data.Currency} ${item.unitPrice}</td>
                        <td>${data.Currency} ${item.quantity * item.unitPrice}</td>
                    </tr>
                `).join('');
                templateHtml = templateHtml.replace(/{{range .Items}}[\s\S]*?{{end}}/, itemsHtml);
            } else {
                // Replace simple variables
                const regex = new RegExp(`{{.${key}}}`, 'g');
                templateHtml = templateHtml.replace(regex, value);
            }
        });

        renderedTemplate.value = templateHtml;
    } catch (error) {
        // Toast.error('Error rendering template:', error);
        // Handle error appropriately
    }
}

// Adjust iframe height after content loads
function iframeLoaded(event) {
    const iframe = event.target;
    iframe.style.height = `${iframe.contentWindow.document.body.scrollHeight + 20}px`;
}

// Watch for invoice changes
watch(() => props.invoice, () => {
    if (props.invoice) {
        fetchAndRenderTemplate();
    }
}, { immediate: true });
</script>

<style scoped>
.overflow-y-auto::-webkit-scrollbar {
    width: 8px;
}

.overflow-y-auto::-webkit-scrollbar-track {
    background: #1a202c;
    border-radius: 4px;
}

.overflow-y-auto::-webkit-scrollbar-thumb {
    background: #4a5568;
    border-radius: 4px;
}

.overflow-y-auto::-webkit-scrollbar-thumb:hover {
    background: #718096;
}
</style>