<template>
    <div class="relative">
        <!-- Original content with blur -->
        <div class="filter blur-sm pointer-events-none">
            <slot></slot>
        </div>

        <!-- Lock overlay -->
        <div
            class="absolute inset-0 bg-gray-900/50 backdrop-blur-sm rounded-lg flex flex-col items-center justify-center p-6 text-center space-y-4">
            <div class="bg-gray-800/80 p-4 rounded-full">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 text-yellow-500" fill="none" viewBox="0 0 24 24"
                    stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
                </svg>
            </div>
            <h3 class="text-lg font-semibold text-white">{{ title }}</h3>
            <p class="text-gray-300 text-sm">Subscribe to unlock this feature</p>
            <button @click="$emit('upgrade')"
                class="mt-4 px-6 py-2 bg-gradient-to-r from-amber-500 to-red-500 text-white rounded-md hover:from-amber-600 hover:to-red-600 transform transition duration-200 hover:scale-105">
                Upgrade Now
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SubscriptionLock',
    props: {
        title: {
            type: String,
            required: true
        }
    }
}
</script>