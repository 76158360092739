// useScroll.js
import { ref, computed } from "vue";

export const useScroll = () => {
    const scrollAreaRef = ref(null);
    const scrollBarRef = ref(null);

    const scrollPercentage = computed(() => {
        if (!scrollAreaRef.value || !scrollBarRef.value) return 0;

        const scrollAreaHeight = scrollAreaRef.value.scrollHeight;
        const scrollAreaVisibleHeight = scrollAreaRef.value.clientHeight;
        const scrollOffset = scrollAreaRef.value.scrollTop;

        return scrollOffset / (scrollAreaHeight - scrollAreaVisibleHeight);
    });

    const handleScroll = () => {
        if (!scrollAreaRef.value || !scrollBarRef.value) return;

        const scrollAreaHeight = scrollAreaRef.value.scrollHeight;
        const scrollAreaVisibleHeight = scrollAreaRef.value.clientHeight;
        const scrollOffset = scrollAreaRef.value.scrollTop;

        const scrollBarHeight = Math.max(
            (scrollAreaVisibleHeight / scrollAreaHeight) *
                scrollAreaVisibleHeight,
            40
        );
        scrollBarRef.value.style.height = `${scrollBarHeight}px`;
        scrollBarRef.value.style.transform = `translateY(${
            (scrollOffset / (scrollAreaHeight - scrollAreaVisibleHeight)) *
            (scrollAreaVisibleHeight - scrollBarHeight)
        }px)`;
    };

    return {
        scrollAreaRef,
        scrollBarRef,
        scrollPercentage,
        handleScroll,
    };
};
