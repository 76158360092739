<template>
    <!-- Loader -->
    <div v-if="isLoading" class="absolute inset-0 flex items-center justify-center z-20 bg-gray-800 bg-opacity-75">
        <svg class="animate-spin h-8 w-8 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
            </path>
        </svg>
    </div>

    <div v-else class="h-full bg-gradient-to-br from-gray-900 to-gray-800 p-8 relative">
        <!-- Header -->
        <div class="mb-8 relative z-10 flex flex-col sm:flex-row gap-4 sm:gap-0 sm:items-center sm:justify-between">
            <h1 class="text-3xl sm:text-4xl font-bold text-white">Dashboard</h1>
            <button @click="createNewInvoice"
                class="flex items-center justify-center gap-2 bg-gradient-to-r from-blue-500 to-purple-600 text-white px-6 py-4 sm:py-3 rounded-xl sm:rounded-lg shadow-lg hover:from-blue-600 hover:to-purple-700 transition duration-300 transform hover:scale-105 w-full sm:w-auto">
                <span class="sm:text-base">Create New Invoice</span>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" class="sm:w-5 sm:h-5"
                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                    <line x1="12" y1="5" x2="12" y2="19"></line>
                    <line x1="5" y1="12" x2="19" y2="12"></line>
                </svg>
            </button>
        </div>

        <!-- Search and Filter -->
        <div v-if="isSubscribed" class="bg-gray-800 rounded-xl sm:rounded-lg shadow-lg mb-8 relative z-10">
            <!-- Search container -->
            <div class="flex flex-col sm:flex-row gap-4 p-4 sm:p-6">
                <div class="flex-1">
                    <div class="relative">
                        <input v-model="searchQuery" type="text" placeholder="Search invoices..."
                            class="w-full p-4 sm:p-3 border border-gray-600 rounded-xl sm:rounded-lg bg-gray-700 text-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300 text-lg sm:text-base" />
                        <button @click="fetchInvoices" :disabled="!isSubscribed"
                            class="absolute right-2 top-1/2 -translate-y-1/2 bg-gradient-to-r from-green-500 to-teal-500 text-white p-2 rounded-lg shadow-lg hover:from-green-600 hover:to-teal-600 transition duration-300">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-6 h-6 sm:w-5 sm:h-5"
                                fill="none" stroke="white" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round">
                                <circle cx="11" cy="11" r="8"></circle>
                                <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <!-- Subscription Notice -->
        <div v-if="!isSubscribed"
            class="mb-6 bg-gray-800/80 p-4 rounded-lg text-white flex flex-col lg:flex-row items-center justify-between relative z-10">
            <div class="flex items-center gap-2">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 text-yellow-500" fill="none" viewBox="0 0 24 24"
                    stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z">
                    </path>
                </svg>
                <span>Subscribe to unlock all your invoices and premium features!</span>
            </div>
            <button @click="goToSubscription"
                class="w-full lg:w-auto mt-4 lg:mt-0 px-6 py-2 bg-gradient-to-r from-amber-500 to-red-500 text-white rounded-md hover:from-amber-600 hover:to-red-600 transition duration-300 font-semibold transform hover:scale-105">
                Subscribe Now
            </button>
        </div>


        <!-- Invoices Grid -->
        <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-6 relative z-10">
            <div v-for="(invoice, index) in invoices" :key="invoice.ID"
                class="relative rounded-lg overflow-hidden bg-gray-800 text-white transform transition duration-300 hover:scale-105 shadow-lg"
                :class="{ 'filter blur-sm': !isSubscribed && index > 0 }">
                <!-- Receipt Header -->
                <div class="text-center py-4 border-b border-dashed border-gray-300">
                    <div class="text-lg font-bold">INVOICE</div>
                    <div class="text-sm">------------------------</div>
                    <div class="text-sm">#{{ invoice.InvoiceNumber }}</div>
                </div>

                <!-- Receipt Content -->
                <div class="p-4">
                    <!-- Client and Date Section -->
                    <div class="mb-4 text-sm">
                        <div class="border-b border-dashed border-gray-300 pb-2">
                            <div class="flex justify-between mb-1">
                                <span>Client:</span>
                                <span class=" font-bold">{{ invoice.UserCompanyName }}</span>
                            </div>
                            <div class="flex justify-between mb-1">
                                <span>Issued Date:</span>
                                <span>{{ formatDate(invoice.IssueDate) }}</span>
                            </div>
                            <div class="flex justify-between mb-1">
                                <span>Due Date:</span>
                                <span>{{ formatDate(invoice.DueDate) }}</span>
                            </div>
                            <!-- <div class="flex justify-between">
                                <span class="text-gray-600">Status:</span>
                                <span :class="getStatusClass(invoice.Status)">{{ invoice.Status }}</span>
                            </div> -->
                        </div>
                    </div>

                    <!-- Divider -->
                    <div class="text-center my-3">
                        <div class="">********************************</div>
                    </div>

                    <!-- Total Section -->
                    <div class="text-center mb-4">
                        <div class="text-lg font-bold">TOTAL</div>
                        <div class="text-xl font-bold mt-1">{{ formatCurrency(invoice.Amount) }}</div>
                    </div>

                    <!-- Footer -->
                    <div class="text-center text-sm mt-4 border-t border-dashed border-gray-300 pt-4">
                        <div class="">------------------------</div>
                        <div class="mt-2">Thank you for your business!</div>
                    </div>
                </div>

                <!-- Action Button Section -->
                <div class="lg:flex-col 2xl:flex-row p-4 flex gap-4 border-t border-dashed border-gray-300">
                    <template v-if="shouldShowPayButton(invoice)">
                        <button @click="handlePayment(invoice)"
                            class="flex items-center justify-center gap-2 w-full bg-gradient-to-r from-amber-500 to-red-500 text-white py-2 px-4 rounded-md hover:from-amber-600 hover:to-red-600 transition duration-300">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 24 24" fill="none"
                                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                <rect x="1" y="4" width="22" height="16" rx="2" ry="2"></rect>
                                <line x1="1" y1="10" x2="23" y2="10"></line>
                            </svg>
                            <span>Pay Invoice</span>
                        </button>
                    </template>
                    <template v-else>
                        <button @click="handleInvoiceClick(invoice)"
                            class="hidden sm:flex items-center justify-center gap-2 w-full bg-gradient-to-r from-blue-500 to-purple-600 text-white py-2 px-4 rounded-md hover:to-purple-700 transition duration-300">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 24 24" fill="none"
                                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                <path d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4" />
                                <polyline points="10 17 15 12 10 7" />
                                <line x1="15" y1="12" x2="3" y2="12" />
                            </svg>
                            <span>Preview</span>
                        </button>
                        <button @click="handleDownload(invoice)"
                            class="flex items-center justify-center gap-2 w-full bg-gradient-to-r from-green-500 to-teal-500 text-white py-2 px-4 rounded-md hover:from-green-600 hover:to-teal-600 transition duration-300"
                            :disabled="isDownloading">
                            <svg viewBox="0 0 24 24" class="h-5 w-5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 3V16M12 16L7 11M12 16L17 11" stroke="currentColor" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M20 21H4" stroke="currentColor" stroke-width="2" stroke-linecap="round" />
                            </svg>
                            <span>{{ isDownloading ? 'Generating...' : 'Download' }}</span>
                        </button>
                    </template>
                </div>

                <!-- Lock Overlay -->
                <div v-if="!isSubscribed && index > 0"
                    class="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center z-20">
                    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none"
                        stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="opacity-75">
                        <rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect>
                        <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
                    </svg>
                </div>
            </div>
            <!-- Add this inside your grid div, after the v-for loop -->
            <div v-if="invoices.length === 0"
                class="col-span-full flex flex-col items-center justify-center p-12 bg-gray-800 rounded-lg shadow-lg relative z-10">
                <div class="bg-gray-700 p-6 rounded-full mb-6">
                    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="text-gray-400">
                        <path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path>
                        <path d="M13 2v7h7"></path>
                        <line x1="7" y1="12" x2="17" y2="12"></line>
                        <line x1="7" y1="16" x2="17" y2="16"></line>
                    </svg>
                </div>

                <h3 class="text-2xl font-bold text-white mb-3">
                    No Invoices Found
                </h3>

                <p class="text-gray-400 text-center mb-6 max-w-md">
                    We couldn't find any invoices matching your search criteria. Try adjusting your filters or create a
                    new invoice.
                </p>

                <button @click="createNewInvoice"
                    class="flex items-center justify-center gap-2 bg-gradient-to-r from-blue-500 to-purple-600 text-white px-6 py-3 rounded-lg shadow-lg hover:from-blue-600 hover:to-purple-700 transition duration-300 transform hover:scale-105">
                    <span>Create New Invoice</span>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                        <line x1="12" y1="5" x2="12" y2="19"></line>
                        <line x1="5" y1="12" x2="19" y2="12"></line>
                    </svg>
                </button>
            </div>
        </div>

        <!-- Pagination -->
        <div class="mt-8 py-8 flex justify-between items-center relative z-10">
            <button @click="previousPage" :disabled="currentPage === 1 || !isSubscribed"
                class="bg-gradient-to-r from-blue-500 to-purple-600 text-white px-6 py-3 rounded-lg shadow-lg hover:from-blue-600 hover:to-purple-700 transition duration-300 disabled:opacity-50 disabled:cursor-not-allowed">
                Previous
            </button>
            <span class="text-gray-300 text-lg">Page {{ currentPage }} of {{ totalPages }}</span>
            <button @click="nextPage" :disabled="currentPage === totalPages || !isSubscribed"
                class="bg-gradient-to-r from-blue-500 to-purple-600 text-white px-6 py-3 rounded-lg shadow-lg hover:from-blue-600 hover:to-purple-700 transition duration-300 disabled:opacity-50 disabled:cursor-not-allowed">
                Next
            </button>
        </div>

        <!-- Download Invoice Modal -->
        <DownloadInvoice v-if="showDownloadModal && selectedInvoice" :invoice="selectedInvoice"
            @close="closeDownloadModal" class="z-20" />
    </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from "vue-router";
import { useToast } from 'vue-toastification';
import DownloadInvoice from '@/components/DownloadInvoice.vue';

const store = useStore();
const router = useRouter();
const toast = useToast();

const invoices = ref([]);
const searchQuery = ref('');
const statusFilter = ref('');
const currentPage = ref(1);
const totalPages = ref(1);
const itemsPerPage = 15;
const showDownloadModal = ref(false);
const selectedInvoice = ref(null);
import { useInvoiceDownload } from '@/composables/useInvoiceDownload';
import api from '@/services/api';

const isLoading = ref(false)
const isSubscribed = computed(() => store.getters['subscription/isSubscribed']);
const { isDownloading, downloadInvoice } = useInvoiceDownload();

const shouldShowPayButton = (invoice) => {
    // If subscribed, never show pay button
    if (isSubscribed.value) {
        return false;
    }

    // If not subscribed, show pay button if invoice is not paid
    return !invoice.IsPaidz;
};

const handlePayment = async (invoice) => {
    if (isSubscribed.value || invoice.is_paid) {
        return;
    }

    try {
        const response = await api.post('/create-checkout-session', {
            priceId: "price_1QDw1QDQwz0FUGJSoS9Ifciq",
            isOneTime: true,
            invoiceId: invoice.ID
        });

        window.location.href = response.data.url;
    } catch (error) {
        console.error('Failed to initiate payment:', error);
        toast.error('Failed to initiate payment. Please try again.');
    }
};


const fetchInvoices = async () => {
    isLoading.value = true; // Set loading state to true at the start
    try {
        // Simulate a 1-second loading time
        await new Promise(resolve => setTimeout(resolve, 400));

        const response = await store.dispatch('invoice/fetchInvoices', {
            page: currentPage.value,
            limit: itemsPerPage,
            search: searchQuery.value,
            status: statusFilter.value,
        });
        invoices.value = response.invoices;
        totalPages.value = response.pagination.total_pages;
    } catch (error) {
        console.error('Failed to fetch invoices:', error);
    } finally {
        isLoading.value = false;
    }
};

const handleInvoiceClick = (invoice, index) => {
    if (!isSubscribed.value && index > 0) {
        router.push('/subscription');
        return;
    }
    openDownloadModal(invoice);
};

const handleDownload = async (invoice, index) => {
    if (!isSubscribed.value && index > 0) {
        router.push('/subscription');
        return;
    }
    await downloadInvoice(invoice);
};

const goToSubscription = () => {
    router.push('/subscription');
};

const createNewInvoice = () => {
    router.push("/invoice/create");
};

const openDownloadModal = (invoice) => {
    selectedInvoice.value = invoice;
    showDownloadModal.value = true;
};

const closeDownloadModal = () => {
    showDownloadModal.value = false;
    selectedInvoice.value = null;
};

const previousPage = () => {
    if (currentPage.value > 1) {
        currentPage.value--;
        fetchInvoices();
    }
};

const nextPage = () => {
    if (currentPage.value < totalPages.value) {
        currentPage.value++;
        fetchInvoices();
    }
};

const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
};

const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amount);
};

const getStatusClass = (status) => {
    const statusClasses = {
        'Draft': 'text-yellow-400',
        'Sent': 'text-blue-400',
        'Paid': 'text-green-400',
        'Overdue': 'text-red-400'
    };
    return statusClasses[status] || 'text-gray-400';
};

onMounted(fetchInvoices);
</script>