<script setup>
import { cn } from '../../lib/utils'
import { computed } from 'vue'
import { Label } from 'radix-vue'

const props = defineProps({
    class: {
        type: String,
        default: ''
    }
})

const delegatedProps = computed(() => {
    const { class: _, ...delegated } = props
    return delegated
})
</script>

<template>
    <Label v-bind="delegatedProps" :class="cn(
        'text-md font-small leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70',
        props.class,
    )">
        <slot />
    </Label>
</template>
