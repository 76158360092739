<template>
    <Transition appear>
        <div v-if="show" class="fixed inset-0 z-50" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <!-- Background overlay -->
            <div class="fixed inset-0 bg-gray-900/75 backdrop-blur-sm transition-opacity" @click="$emit('close')"></div>

            <!-- Modal container -->
            <div class="fixed inset-0">
                <div class="flex min-h-full items-center justify-center p-4 text-center">
                    <div
                        class="relative w-full h-full sm:w-full sm:max-w-2xl text-left sm:rounded-lg shadow-xl bg-gradient-to-br from-gray-900 to-gray-800 border border-gray-700 transform transition-all">
                        <!-- Layout with scrollable content area -->
                        <div class="flex flex-col h-full">
                            <!-- Header - Fixed at top -->
                            <h2
                                class="text-2xl font-bold p-4 sm:p-6 sm:pb-4 text-white border-b border-gray-700 flex-shrink-0">
                                {{ template.name }} Template Preview
                            </h2>

                            <!-- Content - Scrollable when needed -->
                            <div class="flex-1 min-h-0 p-4 sm:p-6 overflow-y-auto">
                                <!-- Image container with max height -->
                                <div class="relative w-full bg-gray-800 p-2 rounded-lg">
                                    <img :src="template.thumbnail" :alt="template.name"
                                        class="w-full h-auto max-h-[50vh] object-contain rounded mx-auto">
                                </div>

                                <!-- Description -->
                                <p class="text-gray-300 text-base mt-4">{{ template.description }}</p>
                            </div>

                            <!-- Buttons - Fixed at bottom -->
                            <div
                                class="p-4 sm:p-6 border-t border-gray-700 bg-gradient-to-br from-gray-800 to-gray-900 flex-shrink-0">
                                <div class="space-y-2 sm:space-y-0 sm:flex sm:flex-row-reverse sm:gap-4">
                                    <button @click="handleSelect" type="button"
                                        class="w-full sm:w-auto bg-gradient-to-r from-blue-500 to-purple-600 text-white px-6 py-3 rounded-lg text-base font-medium shadow-lg hover:from-blue-600 hover:to-purple-700 transition duration-300">
                                        Select Template
                                    </button>
                                    <button @click="$emit('close')" type="button"
                                        class="w-full sm:w-auto bg-gray-800 sm:bg-gradient-to-r sm:from-gray-700 sm:to-gray-800 text-white px-6 py-3 rounded-lg text-base font-medium shadow-lg hover:bg-gray-700 sm:hover:from-gray-600 sm:hover:to-gray-700 transition duration-300 sm:border sm:border-gray-600">
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Transition>
</template>


<script setup>
import { watch } from 'vue'

const props = defineProps({
    show: Boolean,
    template: {
        type: Object,
        required: true,
        default: () => ({})
    }
})

const emit = defineEmits(['close', 'select'])

// Cleanup on modal visibility change
watch(() => props.show, (newVal) => {
    if (newVal) {
        document.body.style.overflow = 'hidden'
    } else {
        document.body.style.overflow = ''
    }
})

// Simple selection handler
const handleSelect = () => {
    emit('select', props.template)
}
</script>

<style scoped>
.backdrop-blur-sm {
    backdrop-filter: blur(8px);
}

/* Maintain the original transition classes */
.ease-out {
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}

.ease-in {
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
}

.duration-200 {
    transition-duration: 200ms;
}

.duration-300 {
    transition-duration: 300ms;
}

.scale-95 {
    transform: scale(0.95);
}

.scale-100 {
    transform: scale(1);
}

.v-enter-active,
.v-leave-active {
    transition: all 0.3s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
    transform: translateY(20px) scale(0.95);
}

.v-enter-to,
.v-leave-from {
    opacity: 1;
    transform: translateY(0) scale(1);
}
</style>