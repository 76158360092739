<template>
  <div class="flex flex-col h-screen">
    <!-- Fixed Header -->
    <nav class="bg-gradient-to-l from-gray-900 to-gray-700 shadow-md z-20 shadow-white/5 flex-none">
      <div class="px-4 py-3 flex justify-between items-center">
        <!-- Logo - Smaller on mobile -->
        <div class="text-2xl font-bold">
          <router-link to="/" class="text-white transition duration-300">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 100" class="w-full">
              <!-- Text layer -->
              <text y="65" font-family="Arial" font-weight="900" font-size="48" letter-spacing="-1">
                <tspan fill="#ef4a42">pay</tspan>
                <tspan fill="#818CF8">me</tspan>
                <tspan fill="#6366F1">maybe</tspan>
              </text>
            </svg>
          </router-link>
        </div>

        <!-- Mobile Menu Button -->
        <button @click="toggleMenu" class="lg:hidden text-white p-2 rounded-lg hover:bg-gray-700/50 transition-colors">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path v-if="!isMenuOpen" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="M4 6h16M4 12h16M4 18h16" />
            <path v-else stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>

        <!-- Desktop Menu -->
        <div class="hidden lg:flex items-center space-x-4 text-white text-lg">
          <router-link v-if="isAuthenticated" to="/profile" active-class="text-teal-400">
            <div class="flex items-center justify-center gap-2 hover:text-teal-400 transition duration-300">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" fill="none" stroke="currentColor"
                stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                <circle cx="12" cy="7" r="4"></circle>
              </svg>
              {{ user.name }}
            </div>
          </router-link>
          <router-link to="/" active-class="text-teal-400" exact-active-class="text-teal-400">
            Dashboard
          </router-link>
          <router-link v-if="!isAuthenticated" to="/login" active-class="text-teal-400">
            Login
          </router-link>
          <button v-if="isAuthenticated" @click="logout"
            class="bg-gradient-to-r from-green-500 to-teal-500 text-white px-6 py-2 rounded-lg shadow-lg hover:from-green-600 hover:to-teal-600 transition duration-300 transform hover:scale-105">
            Logout
          </button>
        </div>
      </div>

      <!-- Mobile Menu -->
      <div v-if="isMenuOpen" class="lg:hidden border-t border-gray-700" @click="isMenuOpen = false">
        <div class="px-4 py-3 space-y-3">
          <router-link v-if="isAuthenticated" to="/profile"
            class="flex items-center gap-2 text-white hover:text-teal-400 transition duration-300 py-2"
            active-class="text-teal-400">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" fill="none" stroke="currentColor"
              stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
              <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
              <circle cx="12" cy="7" r="4"></circle>
            </svg>
            {{ user.name }}
          </router-link>
          <router-link to="/" class="block text-white hover:text-teal-400 transition duration-300 py-2"
            active-class="text-teal-400">
            Dashboard
          </router-link>
          <router-link v-if="!isAuthenticated" to="/login"
            class="block text-white hover:text-teal-400 transition duration-300 py-2" active-class="text-teal-400">
            Login
          </router-link>
          <button v-if="isAuthenticated" @click="logout"
            class="w-full bg-gradient-to-r from-green-500 to-teal-500 text-white px-6 py-2 rounded-lg shadow-lg hover:from-green-600 hover:to-teal-600 transition duration-300">
            Logout
          </button>
        </div>
      </div>
    </nav>


    <!-- Main Content Area -->
    <main class="flex-1 bg-gradient-to-r from-gray-900 to-gray-800 relative overflow-auto">
      <router-view v-slot="{ Component }">
        <component :is="Component" />
      </router-view>
    </main>

    <!-- Fixed Footer -->
    <footer class="h-10 bg-gradient-to-l from-gray-900 to-gray-700 flex items-center justify-center z-20 flex-none">
      <span class="text-sm sm:text-base text-white">© Paymemaybe - All rights reserved</span>
    </footer>
  </div>
</template>

<script>
import { ref, computed, onMounted, onBeforeUnmount } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import store from './store';

export default {
  name: 'App',
  setup() {
    const store = useStore()
    const router = useRouter()
    const isMenuOpen = ref(false)

    const isAuthenticated = computed(() => store.getters['auth/isAuthenticated'])
    const user = computed(() => store.getters['auth/user'])

    const toggleMenu = () => {
      isMenuOpen.value = !isMenuOpen.value
    }

    const closeMenuOnResize = () => {
      if (window.innerWidth >= 1024) {
        isMenuOpen.value = false
      }
    }

    const logout = () => {
      store.dispatch('auth/logout')
      router.push('/login')
      isMenuOpen.value = false
    }

    const checkAuth = () => {
      store.dispatch('auth/checkAuthStatus')
    }

    onMounted(() => {
      checkAuth()
      window.addEventListener('resize', closeMenuOnResize)
    })

    onBeforeUnmount(() => {
      window.removeEventListener('resize', closeMenuOnResize)
    })

    return {
      user,
      isAuthenticated,
      logout,
      isMenuOpen,
      toggleMenu,
    }
  },
}
</script>

<style>
html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

#app {
  height: 100%;
  display: flex;
  flex-direction: column;
}

/* Mobile menu styles */
.menu-open {
  @apply overflow-hidden lg:overflow-auto;
}

/* Animation */
@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.lg\:hidden {
  animation: slideDown 0.2s ease-out;
}

/* WebKit Browsers (Chrome, Safari, Edge) */
*::-webkit-scrollbar {
  width: 8px;
  /* Width of the scrollbar */
  height: 8px;
  /* Height of the scrollbar for horizontal scrollbars */
}

*::-webkit-scrollbar-track {
  background: #1a1a1a;
  /* Track background color */
  border-radius: 10px;
  /* Rounded corners */
}

*::-webkit-scrollbar-thumb {
  background-color: #555;
  /* Color of the scrollbar thumb */
  border-radius: 10px;
  /* Rounded corners on the thumb */
  border: 2px solid #1a1a1a;
  /* Border adds padding to make it thinner */
}

/* Hover effect for the scrollbar thumb */
*::-webkit-scrollbar-thumb:hover {
  background-color: #777;
  /* Slightly lighter color on hover */
}

/* Firefox */
* {
  scrollbar-width: thin;
  /* Makes scrollbar thinner */
  scrollbar-color: #555 #1a1a1a;
  /* Thumb color and track color */
}
</style>