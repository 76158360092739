import { createApp } from "vue";
import App from "./App.vue";
import router from "./router/index";
import store from "./store";
import axios from "axios";
import "./index.css";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

axios.interceptors.request.use((config) => {
    const token = store.state.auth.token;
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

const app = createApp(App);

// Toast Configuration
const toastOptions = {
    position: "bottom-right",
    timeout: 3000,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    hideProgressBar: false,
    closeButton: "button",
    icon: true,
    rtl: false,
};

app.use(Toast, toastOptions);

app.use(store).use(router).mount("#app");
