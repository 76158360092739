import api from "@/services/api";

export default {
    namespaced: true,
    state: {
        currentInvoice: null,
        invoices: [],
        pagination: null,
        summary: null,
    },
    mutations: {
        SET_CURRENT_INVOICE(state, invoice) {
            state.currentInvoice = invoice;
        },
        SET_INVOICES(state, invoices) {
            state.invoices = invoices;
        },
        SET_PAGINATION(state, pagination) {
            state.pagination = pagination;
        },
        SET_SUMMARY(state, summary) {
            state.summary = summary;
        },
    },
    actions: {
        async createInvoice({ commit }, invoiceData) {
            console.log(invoiceData);
            try {
                const response = await api.post("/invoices", invoiceData);
                commit("SET_CURRENT_INVOICE", response.data);
                return response.data;
            } catch (error) {
                console.error("Error creating invoice:", error);
                throw error;
            }
        },
        async fetchInvoices({ commit }, params) {
            try {
                const response = await api.get("/invoices", { params });
                commit("SET_INVOICES", response.data.invoices);
                commit("SET_PAGINATION", response.data.pagination);
                return response.data;
            } catch (error) {
                console.error("Error fetching invoices:", error);
                throw error;
            }
        },
        async fetchInvoice({ commit }, invoiceId) {
            console.log("HERE");
            try {
                const response = await api.get(`/invoices/${invoiceId}`);
                commit("SET_CURRENT_INVOICE", response.data);
                return response.data;
            } catch (error) {
                console.error("Error fetching invoice:", error);
                throw error;
            }
        },
        async updateInvoice({ commit }, { invoiceId, invoiceData }) {
            try {
                const response = await api.put(
                    `/invoices/${invoiceId}`,
                    invoiceData
                );
                commit("SET_CURRENT_INVOICE", response.data);
                return response.data;
            } catch (error) {
                console.error("Error updating invoice:", error);
                throw error;
            }
        },
        async deleteInvoice({ dispatch }, invoiceId) {
            try {
                await api.delete(`/invoices/${invoiceId}`);
                // Refresh the invoice list after deletion
                dispatch("fetchInvoices");
            } catch (error) {
                console.error("Error deleting invoice:", error);
                throw error;
            }
        },
        async fetchInvoiceSummary({ commit }, params) {
            try {
                const response = await api.get("/invoices/summary", {
                    params,
                });
                commit("SET_SUMMARY", response.data);
                return response.data;
            } catch (error) {
                console.error("Error fetching invoice summary:", error);
                throw error;
            }
        },
        async generateInvoicePDF(_, invoiceId) {
            try {
                const response = await api.get(`/invoices/${invoiceId}/pdf`, {
                    responseType: "blob",
                });
                const url = window.URL.createObjectURL(
                    new Blob([response.data])
                );
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", `invoice-${invoiceId}.pdf`);
                document.body.appendChild(link);
                link.click();
            } catch (error) {
                console.error("Error generating invoice PDF:", error);
                throw error;
            }
        },
    },
    getters: {
        getCurrentInvoice: (state) => state.currentInvoice,
        getInvoices: (state) => state.invoices,
        getPagination: (state) => state.pagination,
        getSummary: (state) => state.summary,
    },
};
