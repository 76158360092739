// src/composables/useFormValidation.js

import { computed, ref } from "vue";

export const useFormValidation = (form) => {
    // Add touched state tracking
    const touchedFields = ref(new Set());
    const formSubmitAttempted = ref(false);

    // Validation regex patterns
    const patterns = {
        name: /^[a-zA-Z\s'-]{2,50}$/,
        email: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
        address: /^[a-zA-Z0-9\s,.-]{5,100}$/,
        companyName: /^[a-zA-Z0-9\s&'-]{2,50}$/,
        vat: /^[A-Z0-9-]{5,20}$/,
        invoiceNumber: /^[A-Z0-9-]{3,20}$/,
        description: /^[a-zA-Z0-9\s,.-]{3,100}$/,
        longText: /^[a-zA-Z0-9\s,.\-_'"();:!?&%$@#*+=]{0,2000}$/,
    };

    const errors = computed(() => {
        const errors = {};

        // Only validate if the field has been touched or form submission was attempted
        const shouldValidate = (fieldName) =>
            touchedFields.value.has(fieldName) || formSubmitAttempted.value;

        // User Information Validation
        if (shouldValidate("userName")) {
            if (!form.value.userName?.trim()) {
                errors.userName = "Your name is required";
            } else if (!patterns.name.test(form.value.userName)) {
                errors.userName =
                    "Name can only contain letters, spaces, hyphens, and apostrophes";
            }
        }

        if (shouldValidate("userEmail")) {
            if (!form.value.userEmail?.trim()) {
                errors.userEmail = "Your email is required";
            } else if (!patterns.email.test(form.value.userEmail)) {
                errors.userEmail = "Please enter a valid email address";
            }
        }

        if (shouldValidate("userAddress")) {
            if (!form.value.userAddress?.trim()) {
                errors.userAddress = "Your address is required";
            } else if (!patterns.address.test(form.value.userAddress)) {
                errors.userAddress = "Address contains invalid characters";
            }
        }

        if (shouldValidate("userCompanyName")) {
            if (!form.value.userCompanyName?.trim()) {
                errors.userCompanyName = "Your company name is required";
            } else if (!patterns.companyName.test(form.value.userCompanyName)) {
                errors.userCompanyName =
                    "Company name contains invalid characters";
            }
        }

        if (
            shouldValidate("userCompanyVAT") &&
            form.value.userCompanyVAT &&
            !patterns.vat.test(form.value.userCompanyVAT)
        ) {
            errors.userCompanyVAT =
                "VAT number can only contain uppercase letters, numbers, and hyphens";
        }

        // Customer Information Validation
        if (shouldValidate("customerName")) {
            if (!form.value.customerName?.trim()) {
                errors.customerName = "Customer name is required";
            } else if (!patterns.name.test(form.value.customerName)) {
                errors.customerName =
                    "Customer name can only contain letters, spaces, hyphens, and apostrophes";
            }
        }

        if (shouldValidate("customerEmail")) {
            if (!form.value.customerEmail?.trim()) {
                errors.customerEmail = "Customer email is required";
            } else if (!patterns.email.test(form.value.customerEmail)) {
                errors.customerEmail =
                    "Please enter a valid customer email address";
            }
        }

        if (shouldValidate("customerAddress")) {
            if (!form.value.customerAddress?.trim()) {
                errors.customerAddress = "Customer address is required";
            } else if (!patterns.address.test(form.value.customerAddress)) {
                errors.customerAddress =
                    "Customer address contains invalid characters";
            }
        }

        if (shouldValidate("customerCompanyName")) {
            if (!form.value.customerCompanyName?.trim()) {
                errors.customerCompanyName =
                    "Customer company name is required";
            } else if (
                !patterns.companyName.test(form.value.customerCompanyName)
            ) {
                errors.customerCompanyName =
                    "Customer company name contains invalid characters";
            }
        }

        if (
            shouldValidate("customerCompanyVAT") &&
            form.value.customerCompanyVAT &&
            !patterns.vat.test(form.value.customerCompanyVAT)
        ) {
            errors.customerCompanyVAT =
                "Customer VAT number can only contain uppercase letters, numbers, and hyphens";
        }

        // Invoice Details Validation
        if (shouldValidate("invoiceNumber")) {
            if (!form.value.invoiceNumber?.trim()) {
                errors.invoiceNumber = "Invoice number is required";
            } else if (!patterns.invoiceNumber.test(form.value.invoiceNumber)) {
                errors.invoiceNumber =
                    "Invoice number can only contain uppercase letters, numbers, and hyphens";
            }
        }

        if (shouldValidate("currency") && !form.value.currency) {
            errors.currency = "Please select a currency";
        }

        if (shouldValidate("issueDate") && !form.value.issueDate) {
            errors.issueDate = "Issue date is required";
        }

        if (shouldValidate("dueDate")) {
            if (!form.value.dueDate) {
                errors.dueDate = "Due date is required";
            } else if (
                form.value.issueDate &&
                new Date(form.value.dueDate) < new Date(form.value.issueDate)
            ) {
                errors.dueDate = "Due date cannot be earlier than issue date";
            }
        }

        // Line Items Validation
        if (
            shouldValidate("items") &&
            (!form.value.items?.length || form.value.items.length === 0)
        ) {
            errors.items = "At least one item is required";
        } else {
            form.value.items.forEach((item, index) => {
                const descFieldName = `items.${index}.description`;
                const qtyFieldName = `items.${index}.quantity`;
                const priceFieldName = `items.${index}.unitPrice`;

                if (shouldValidate(descFieldName)) {
                    if (!item.description?.trim()) {
                        errors[descFieldName] = "Item description is required";
                    } else if (!patterns.description.test(item.description)) {
                        errors[descFieldName] =
                            "Description contains invalid characters";
                    }
                }

                if (shouldValidate(qtyFieldName)) {
                    if (!item.quantity || item.quantity < 1) {
                        errors[qtyFieldName] = "Quantity must be at least 1";
                    } else if (!Number.isInteger(Number(item.quantity))) {
                        errors[qtyFieldName] =
                            "Quantity must be a whole number";
                    }
                }

                if (shouldValidate(priceFieldName)) {
                    if (!item.unitPrice || item.unitPrice < 0) {
                        errors[priceFieldName] =
                            "Unit price must be greater than or equal to 0";
                    } else if (isNaN(Number(item.unitPrice))) {
                        errors[priceFieldName] =
                            "Unit price must be a valid number";
                    }
                }
            });
        }

        // Notes Validation
        if (shouldValidate("notes")) {
            if (form.value.notes && !patterns.longText.test(form.value.notes)) {
                errors.notes = "Notes contain invalid characters";
            } else if (form.value.notes && form.value.notes.length > 500) {
                errors.notes = "Notes cannot exceed 500 characters";
            }
        }

        // Terms and Conditions Validation
        if (shouldValidate("termsAndConditions")) {
            if (
                form.value.termsAndConditions &&
                !patterns.longText.test(form.value.termsAndConditions)
            ) {
                errors.termsAndConditions =
                    "Terms & Conditions contain invalid characters";
            } else if (
                form.value.termsAndConditions &&
                form.value.termsAndConditions.length > 1000
            ) {
                errors.termsAndConditions =
                    "Terms & Conditions cannot exceed 1000 characters";
            }
        }

        return errors;
    });

    // Input sanitization functions
    const sanitizeFunctions = {
        name: (value) => value.replace(/[^a-zA-Z\s'-]/g, ""),
        address: (value) => value.replace(/[^a-zA-Z0-9\s,.-]/g, ""),
        companyName: (value) => value.replace(/[^a-zA-Z0-9\s&'-]/g, ""),
        vat: (value) => value.replace(/[^A-Z0-9-]/g, "").toUpperCase(),
        invoiceNumber: (value) =>
            value.replace(/[^A-Z0-9-]/g, "").toUpperCase(),
        description: (value) => value.replace(/[^a-zA-Z0-9\s,.-]/g, ""),
        longText: (value) =>
            value.replace(/[^a-zA-Z0-9\s,.\-_'"();:!?&%$@#*+=]/g, ""),
    };

    const sanitizeInput = (value, type) => {
        const sanitizeFunc = sanitizeFunctions[type];
        return sanitizeFunc ? sanitizeFunc(value) : value;
    };

    const isValid = computed(() => Object.keys(errors.value).length === 0);

    const validateField = (fieldName) => {
        // Only validate if field has been touched (user interacted)
        if (!touchedFields.value.has(fieldName)) {
            return "";
        }

        // Get the field value
        const value = fieldName.includes(".")
            ? fieldName.split(".").reduce((obj, key) => obj?.[key], form.value)
            : form.value[fieldName];

        // Only validate if there's a value or if form was submitted
        if (!value && !formSubmitAttempted.value) {
            return "";
        }

        return errors.value[fieldName] || "";
    };

    const validateItemField = (index, field) => {
        const fieldName = `items.${index}.${field}`;

        // Only validate if field has been touched
        if (!touchedFields.value.has(fieldName)) {
            return "";
        }

        const item = form.value.items[index];
        const value = item[field];

        // Only validate if there's a value or if form was submitted
        if (!value && !formSubmitAttempted.value) {
            return "";
        }

        return errors.value[fieldName] || "";
    };

    const touchField = (fieldName) => {
        touchedFields.value.add(fieldName);
    };

    const resetTouchedFields = () => {
        touchedFields.value.clear();
        formSubmitAttempted.value = false;
    };

    const setFormSubmitted = () => {
        formSubmitAttempted.value = true;
    };

    return {
        errors,
        isValid,
        validateField,
        validateItemField,
        sanitizeInput,
        touchField,
        resetTouchedFields,
        setFormSubmitted,
    };
};
