<template>
    <div
        class="fixed h-full inset-0 bg-gradient-to-r from-blue-500 to-purple-600 flex flex-col items-center justify-center z-50 p-8 overflow-auto">
        <h2 class="text-3xl text-white font-bold text-center mb-12">Choose Your Plan & Get Access to Your Invoice</h2>
        <div class="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-6xl w-full">
            <!-- Pay Per Invoice -->
            <div class="flex flex-col bg-white rounded-lg shadow-lg overflow-hidden">
                <div class="px-6 py-8">
                    <h3 class="text-2xl font-semibold text-gray-900">Pay Per Invoice</h3>
                    <p class="mt-2 text-gray-600">Perfect for one-time usage</p>
                    <p class="mt-4">
                        <span class="text-4xl font-extrabold text-gray-900">€3.99</span>
                        <span class="text-base font-medium text-gray-500">/invoice</span>
                    </p>
                    <ul class="mt-6 space-y-4">
                        <li class="flex items-start">
                            <svg class="flex-shrink-0 h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24"
                                stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                    d="M5 13l4 4L19 7" />
                            </svg>
                            <span class="ml-3 text-base text-gray-700">Single invoice generation</span>
                        </li>
                        <li class="flex items-start">
                            <svg class="flex-shrink-0 h-6 w-6 text-red-500" fill="none" viewBox="0 0 24 24"
                                stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                    d="M6 18L18 6M6 6l12 12" />
                            </svg>
                            <span class="ml-3 text-base text-gray-700">No invoice history</span>
                        </li>
                        <li class="flex items-start">
                            <svg class="flex-shrink-0 h-6 w-6 text-red-500" fill="none" viewBox="0 0 24 24"
                                stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                    d="M6 18L18 6M6 6l12 12" />
                            </svg>
                            <span class="ml-3 text-base text-gray-700">Limited template selection</span>
                        </li>
                        <li class="flex items-start">
                            <svg class="flex-shrink-0 h-6 w-6 text-red-500" fill="none" viewBox="0 0 24 24"
                                stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                    d="M6 18L18 6M6 6l12 12" />
                            </svg>
                            <span class="ml-3 text-base text-gray-700">No statistics & overview</span>
                        </li>
                    </ul>
                </div>
                <div class="px-6 py-8 bg-gray-50 mt-auto">
                    <button @click="redirectToCheckout(plans[0].priceId, true)" :disabled="isLoading"
                        class="w-full bg-gradient-to-r from-blue-500 to-purple-600 text-white rounded-md px-4 py-3 hover:from-blue-600 hover:to-purple-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition-colors disabled:opacity-50 disabled:cursor-not-allowed">
                        {{ isLoading ? 'Processing...' : 'Buy Single Invoice' }}
                    </button>
                </div>
            </div>

            <!-- Monthly Pro -->
            <div
                class="flex flex-col bg-white rounded-lg shadow-lg overflow-hidden transform scale-105 border-2 border-blue-500">
                <div class="absolute top-0 right-0 mt-4 mr-4">
                    <span
                        class="px-3 py-1 text-sm font-semibold text-white bg-gradient-to-r from-blue-500 to-purple-600 rounded-full shadow-md">
                        Most Popular
                    </span>
                </div>
                <div class="px-6 py-8">
                    <h3 class="text-2xl font-semibold text-gray-900">Pro Monthly</h3>
                    <p class="mt-2 text-gray-600">Professional features, monthly billing</p>
                    <p class="mt-4">
                        <span class="text-4xl font-extrabold text-gray-900">€9.99</span>
                        <span class="text-base font-medium text-gray-500">/month</span>
                    </p>
                    <ul class="mt-6 space-y-4">
                        <li class="flex items-start">
                            <svg class="flex-shrink-0 h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24"
                                stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                    d="M5 13l4 4L19 7" />
                            </svg>
                            <span class="ml-3 text-base text-gray-700">Unlimited invoices</span>
                        </li>
                        <li class="flex items-start">
                            <svg class="flex-shrink-0 h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24"
                                stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                    d="M5 13l4 4L19 7" />
                            </svg>
                            <span class="ml-3 text-base text-gray-700">Complete invoice history</span>
                        </li>
                        <li class="flex items-start">
                            <svg class="flex-shrink-0 h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24"
                                stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                    d="M5 13l4 4L19 7" />
                            </svg>
                            <span class="ml-3 text-base text-gray-700">10+ Premium templates</span>
                        </li>
                        <li class="flex items-start">
                            <svg class="flex-shrink-0 h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24"
                                stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                    d="M5 13l4 4L19 7" />
                            </svg>
                            <span class="ml-3 text-base text-gray-700">Email support</span>
                        </li>
                        <li class="flex items-start">
                            <svg class="flex-shrink-0 h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24"
                                stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                    d="M5 13l4 4L19 7" />
                            </svg>
                            <span class="ml-3 text-base text-gray-700">Statistics & Revenue overview</span>
                        </li>
                    </ul>
                </div>
                <div class="px-6 py-8 bg-gray-50 mt-auto">
                    <button @click="redirectToCheckout(plans[1].priceId, false)" :disabled="isLoading"
                        class="w-full bg-gradient-to-r from-blue-500 to-purple-600 text-white rounded-md px-4 py-3 hover:from-blue-600 hover:to-purple-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition-colors disabled:opacity-50 disabled:cursor-not-allowed">
                        {{ isLoading ? 'Processing...' : 'Start Pro Monthly' }}
                    </button>
                </div>
            </div>

            <!-- Yearly Pro -->
            <div class="flex flex-col bg-white rounded-lg shadow-lg overflow-hidden">
                <div class="absolute top-0 right-0 mt-4 mr-4">
                    <span
                        class="px-3 py-1 text-sm font-semibold text-white bg-gradient-to-r from-green-500 to-teal-500 rounded-full shadow-md">
                        Save 30%
                    </span>
                </div>
                <div class="px-6 py-8">
                    <h3 class="text-2xl font-semibold text-gray-900">Pro Yearly</h3>
                    <p class="mt-2 text-gray-600">Our best value, annual billing</p>
                    <p class="mt-4">
                        <span class="text-4xl font-extrabold text-gray-900">€83.99</span>
                        <span class="text-base font-medium text-gray-500">/year</span>
                    </p>
                    <p class="mt-1 text-sm text-green-600">€6.99/month, billed annually</p>
                    <ul class="mt-6 space-y-4">
                        <li class="flex items-start">
                            <svg class="flex-shrink-0 h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24"
                                stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                    d="M5 13l4 4L19 7" />
                            </svg>
                            <span class="ml-3 text-base text-gray-700">All Pro Monthly features</span>
                        </li>
                        <li class="flex items-start">
                            <svg class="flex-shrink-0 h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24"
                                stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                    d="M5 13l4 4L19 7" />
                            </svg>
                            <span class="ml-3 text-base text-gray-700">30% discount</span>
                        </li>
                        <li class="flex items-start">
                            <svg class="flex-shrink-0 h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24"
                                stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                    d="M5 13l4 4L19 7" />
                            </svg>
                            <span class="ml-3 text-base text-gray-700">Priority email support</span>
                        </li>
                    </ul>
                </div>
                <div class="px-6 py-8 bg-gray-50 mt-auto">
                    <button @click="redirectToCheckout(plans[2].priceId, false)" :disabled="isLoading"
                        class="w-full bg-gradient-to-r from-blue-500 to-purple-600 text-white rounded-md px-4 py-3 hover:from-blue-600 hover:to-purple-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition-colors disabled:opacity-50 disabled:cursor-not-allowed">
                        {{ isLoading ? 'Processing...' : 'Start Pro Yearly' }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';
import { useStore } from 'vuex';

export default {
    name: 'SubscriptionPlans',
    emits: ['close', 'payment-completed'],
    props: {
        invoiceId: {
            type: Number,
            required: true
        }
    },
    setup(props) {
        const store = useStore();
        const error = ref('');
        const isLoading = ref(false);

        // Calculate yearly price with 30% discount
        const monthlyPrice = 9.99;
        const yearlyPrice = (monthlyPrice * 12 * 0.7).toFixed(2); // 30% off
        const monthlyEquivalent = (yearlyPrice / 12).toFixed(2);

        const plans = [
            {
                name: "Pay Per Invoice",
                price: "3.99",
                priceId: 'price_1QDw1QDQwz0FUGJSoS9Ifciq',
            },
            {
                name: "Pro Monthly",
                price: "9.99",
                priceId: 'price_1QDw28DQwz0FUGJSDi1IDCMr',
            },
            {
                name: "Pro Yearly",
                price: yearlyPrice,
                priceId: 'price_1QDw3FDQwz0FUGJS98mg6EMJ',
            }
        ];

        const redirectToCheckout = async (priceId, isOneTime = false) => {
            isLoading.value = true;
            error.value = '';
            try {
                const checkoutUrl = await store.dispatch('subscription/createCheckoutSession', {
                    priceId,
                    isOneTime,
                    invoiceId: props.invoiceId,
                    planName: isOneTime ? null : plans.find(p => p.priceId === priceId)?.name
                });

                if (checkoutUrl) {
                    window.location.href = checkoutUrl;
                } else {
                    throw new Error('No checkout URL received');
                }
            } catch (err) {
                console.error('Error creating checkout session:', err);
                error.value = 'Failed to initiate checkout. Please try again.';
            } finally {
                isLoading.value = false;
            }
        };

        return { error, isLoading, plans, redirectToCheckout };
    },
};
</script>