<script setup>
import { ref, computed, watch } from 'vue'
import { CheckIcon, ChevronDown, ChevronUp, Search } from 'lucide-vue-next'
import { cn } from "@/lib/utils"
import Input from './Input.vue'
import ScrollArea from './ScrollArea.vue'
import ScrollBar from './ScrollBar.vue'

const props = defineProps({
    modelValue: {
        type: [String, Number],
        default: '',
    },
    options: {
        type: Array,
        default: () => [],
    },
    placeholder: {
        type: String,
        default: 'Select an option',
    },
    class: {
        type: String,
        default: ""
    }
})

const emit = defineEmits(['update:modelValue'])

const isOpen = ref(false)
const selectedOption = ref(props.modelValue)
const searchText = ref('')

const filteredOptions = computed(() => {
    const lowerCaseSearch = searchText.value.toLowerCase()
    return props.options.filter(option =>
        option.label.toLowerCase().includes(lowerCaseSearch)
    )
})

const displayValue = computed(() => {
    const option = props.options.find(opt => opt.value === selectedOption.value)
    return option ? option.label : props.placeholder
})

const toggleDropdown = () => {
    isOpen.value = !isOpen.value
}

const selectOption = (value) => {
    selectedOption.value = value
    isOpen.value = false
    emit('update:modelValue', value)
}

watch(() => props.modelValue, (newValue) => {
    selectedOption.value = newValue
})
</script>

<template>
    <div class="relative w-full">
        <button type="button" @click="toggleDropdown" :class="cn(
            'p-3 border border-gray-600 rounded-lg lg:w-full sm:w-full bg-gray-700 text-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300 flex justify-between items-center',
            props.class
        )">
            {{ displayValue }}
            <ChevronDown v-if="!isOpen" class="h-4 w-4 opacity-50" />
            <ChevronUp v-else class="h-4 w-4 opacity-50" />
        </button>
        <div v-if="isOpen"
            class="absolute left-0 right-0 z-50 mt-1 max-h-[300px] w-full overflow-hidden rounded-md  bg-gray-800 text-gray-200 shadow-md">
            <div class="px-2 py-1">
                <div class="relative">
                    <Input v-model="searchText" type="search" placeholder="Search..." class="mb-2">
                    <template #prefix>
                        <Search class="h-4 w-4 opacity-50" />
                    </template>
                    </Input>
                </div>
            </div>
            <ScrollArea class="max-h-[200px] bg-gray-800 text-gray-200">
                <ul class="py-1.5 px-1">
                    <li v-for="option in filteredOptions" :key="option.value" @click="selectOption(option.value)"
                        class="relative flex cursor-default select-none items-center rounded-sm py-1.5 px-2 outline-none focus:bg-blue-600 focus:text-white data-[disabled]:pointer-events-none data-[disabled]:opacity-50 hover:bg-blue-600 hover:text-white">
                        {{ option.label }}
                        <CheckIcon v-if="option.value === selectedOption" class="ml-auto h-4 w-4" />
                    </li>
                </ul>
            </ScrollArea>
            <ScrollBar class="h-[200px] bg-gray-700" />
        </div>
    </div>
</template>