<template>
    <div class="relative customer-search">
        <!-- Search Input -->
        <div class="relative">
            <Input v-model="searchQuery" type="text" placeholder="Search customers..."
                class="w-full bg-gray-700 text-gray-200" @focus="handleFocus" @input="handleSearch" />
            <button v-if="searchQuery" @click="clearSearch"
                class="absolute right-2 top-1/2 -translate-y-1/2 text-gray-400 hover:text-gray-200">
                ×
            </button>
        </div>

        <!-- Dropdown -->
        <div v-show="showDropdown && (isLoading || customers.length > 0)"
            class="absolute z-50 w-full mt-1 bg-gray-700 rounded-md shadow-lg border border-gray-600">
            <div v-if="isLoading" class="p-2 text-sm text-gray-300">
                Searching...
            </div>
            <div v-else class="max-h-60 overflow-y-auto">
                <div v-for="customer in customers" :key="customer.ID" @click="selectCustomer(customer)"
                    class="p-2 hover:bg-gray-600 cursor-pointer">
                    <div class="text-white font-medium">{{ customer.Name }}</div>
                    <div class="text-sm text-gray-300">{{ customer.Email }}</div>
                    <div v-if="customer.CompanyName" class="text-sm text-gray-400">
                        {{ customer.CompanyName }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, watch, onMounted, onBeforeUnmount } from 'vue'
import { debounce } from 'lodash'
import Input from "@/components/ui/Input.vue"
import api from '@/services/api'

const emit = defineEmits(['customer-selected'])

const searchQuery = ref('')
const customers = ref([])
const isLoading = ref(false)
const showDropdown = ref(false)

const handleFocus = () => {
    console.log('Input focused')
    showDropdown.value = true
    if (searchQuery.value) {
        searchCustomers(searchQuery.value)
    }
}

// Debounced search function
const searchCustomers = debounce(async (query) => {
    console.log('Searching for:', query)
    if (!query || query.length < 2) {
        customers.value = []
        isLoading.value = false
        return
    }

    try {
        isLoading.value = true
        const response = await api.get(`/customers/suggestions?q=${encodeURIComponent(query)}`)
        console.log('Search response:', response.data)
        customers.value = response.data
        showDropdown.value = true // Ensure dropdown is shown when we have results
    } catch (error) {
        console.error('Error fetching customers:', error)
        customers.value = []
    } finally {
        isLoading.value = false
    }
}, 300)

const handleSearch = (event) => {
    searchQuery.value = event.target.value
    if (searchQuery.value) {
        searchCustomers(searchQuery.value)
    } else {
        customers.value = []
    }
}

const selectCustomer = (customer) => {
    console.log('Selected customer:', customer)
    emit('customer-selected', {
        name: customer.Name,
        email: customer.Email,
        address: customer.Address,
        company_name: customer.CompanyName,
        company_vat: customer.CompanyVAT
    })
    searchQuery.value = customer.Name
    showDropdown.value = false
}

const clearSearch = () => {
    searchQuery.value = ''
    customers.value = []
    showDropdown.value = false
}

// Close dropdown when clicking outside
const handleClickOutside = (event) => {
    if (!event.target.closest('.customer-search')) {
        console.log('Clicked outside, closing dropdown')
        showDropdown.value = false
    }
}

onMounted(() => {
    document.addEventListener('click', handleClickOutside)
})

onBeforeUnmount(() => {
    document.removeEventListener('click', handleClickOutside)
})

// Debug watcher
watch(() => customers.value, (newCustomers) => {
    console.log('Customers updated:', newCustomers)
}, { deep: true })

watch(() => showDropdown.value, (newValue) => {
    console.log('Dropdown visibility changed:', newValue)
})
</script>

<style scoped>
.customer-search {
    position: relative;
    width: 100%;
}

/* Ensure dropdown is above other elements */
.customer-search .dropdown {
    position: absolute;
    width: 100%;
    z-index: 50;
}
</style>